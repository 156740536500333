import classnames from 'classnames/bind';
import { useLoadKakaoScript } from '../../../hook/map/useLoadKakaoScript';
import IViewContents from '../../../interface/contents/IViewContents';
import { IMap } from '../../../interface/contents/map/IMap';
import Map from './MapModule/Map';
import styles from './ViewMap.module.scss';
import { ViewModeType } from '../../../interface/common/ViewMode';
const cx = classnames.bind(styles);

interface IProps {
  mapContent: IViewContents<IMap>;
  viewMode?: ViewModeType;
}

const ViewMap = ({ mapContent, viewMode }: IProps) => {
  const [isLoadKakaoMap] = useLoadKakaoScript();
  if (!isLoadKakaoMap) return null;

  // 에러 처리 필요 시, useLoadKakaoScript에서 error도 반환하고 있기 때문에 처리 가능

  return <Map mapContent={mapContent as IMap} viewMode={viewMode} />;
};

export default ViewMap;
