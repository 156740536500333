import { FC, useState, useEffect, useMemo, useRef } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import classnames from 'classnames/bind';
import styles from './ViewBannerImage.module.scss';
import ViewBannerText from './ViewBannerText';
import { aToHex } from '../../../common/colorPicker/utils/convert';
import { HasOption } from '../../../interface/common/hasOption';
import useFileSize from '../../../hook/useFileSize';
import { BACKGROUND_MEDIA } from '../../../hook/useBackgroundFile';
import { BANNER_TYPE, BannerSizeMap } from '../../../interface/layout/banner/banner.constant';
import { IBannerDesktopStyle } from '../../../interface/layout/banner/IBannerDesktopStyle';
import { IBannerMobileStyle } from '../../../interface/layout/banner/IBannerMobileStyle';
import { Ratio } from '../../../interface/common/Ratio';
import { useLayoutBannerBackgroundFile } from '../../../hook/useLayoutBannerBackgroundFile';
import { isOnOff, OFF } from '../../../constants/common';
import { DefaultBorder } from '../../../interface/common/Border';
import { SIZE } from '../../../interface/common/Size';
const cx = classnames.bind(styles);

interface IViewBannerImageProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  onClick?: () => void;
  slideIndex?: number;
  shouldRender?: boolean;
}

const ViewBannerImage: FC<IViewBannerImageProps & HasOption> = ({
  viewMode = VIEW_MODE.PC,
  viewLayout,
  isCapture = false,
  isPreview,
  onClick,
  slideIndex,
  options,
  shouldRender = true,
}) => {
  const banner = viewLayout.banner!;
  const { companyName, getPathName, findPageTitle } = options?.pageMetadataHooks ?? {
    companyName: undefined,
    getPathName: undefined,
    findPageTitle: undefined,
  };
  const bannerType = banner?.type ?? BANNER_TYPE.BASIC;
  const bannerStyle = useMemo<IBannerDesktopStyle | IBannerMobileStyle | undefined>(() => {
    if (bannerType === BANNER_TYPE.BASIC) {
      if (viewMode === VIEW_MODE.MOBILE && isOnOff(viewLayout.banner?.bannerBasic.mobileStyleToggle ?? OFF))
        return viewLayout.banner?.bannerBasic.mobileStyle;
      return viewLayout.banner?.bannerBasic.desktopStyle;
    }
    if (bannerType === BANNER_TYPE.SLIDE) {
      if (viewMode === VIEW_MODE.MOBILE && isOnOff(viewLayout.banner?.bannerSlide.mobileStyleToggle ?? OFF))
        return viewLayout.banner?.bannerSlide.mobileStyle;
      return viewLayout.banner?.bannerSlide.desktopStyle;
    }
  }, [bannerType, viewLayout, viewMode]);

  const mediaWrapperRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { fileUrl: _fileUrl, fileType } = useLayoutBannerBackgroundFile({
    banner,
    viewMode,
    currentIndex: slideIndex,
  });

  const fileUrl = _fileUrl ?? '';
  const { fileSize: mediaFileSize } = useFileSize({ fileType, fileUrl });

  const isLargeMobileMedia = useMemo(
    () => viewMode === VIEW_MODE.MOBILE && mediaFileSize.height > 800,
    [viewMode, mediaFileSize.height]
  );

  const getAltText = () => {
    if (!companyName || !getPathName || !findPageTitle) return '';
    return `${companyName}의 ${findPageTitle(getPathName())}을(를) 소개하는 배너입니다.`;
  };

  const onLoadedVideoData = () => {
    setIsVideoLoaded(true);
  };

  const bannerMobileStyle = bannerStyle as IBannerMobileStyle;
  const bannerDesktopStyle = bannerStyle as IBannerDesktopStyle;

  const width = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE) return `${bannerMobileStyle?.sizePercentage ?? 100}%`;
    if ((bannerDesktopStyle?.ratioType ?? Ratio.FULL) === Ratio.FULL) return '100%';
    if (bannerDesktopStyle?.width) return `${bannerDesktopStyle.width}px`;
    return '100%';
  }, [viewMode, bannerMobileStyle, bannerDesktopStyle]);
  const height = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE) return `${mediaFileSize.height}px`;
    if (isOnOff(bannerDesktopStyle?.imageOptimizationToggle ?? OFF)) return 'auto';
    if (bannerDesktopStyle?.height) return `${bannerDesktopStyle?.height}px`;
    return `${BannerSizeMap[SIZE.MEDIUM].height}px`;
  }, [viewMode, bannerDesktopStyle, mediaFileSize]);
  const aspectRatio = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE || !isOnOff(bannerDesktopStyle?.imageOptimizationToggle)) return 'auto';
    return `${mediaFileSize.width} / ${mediaFileSize.height}`;
  }, [viewMode, mediaFileSize, bannerDesktopStyle]);
  const ratioType = useMemo(() => {
    if (viewMode === VIEW_MODE.PC && bannerDesktopStyle?.ratioType) return bannerDesktopStyle.ratioType;
    return undefined;
  }, [viewMode, bannerDesktopStyle]);
  const getBorderRadius = () => {
    const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } =
      bannerStyle?.borderRadius ?? DefaultBorder;
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  };

  useEffect(() => {
    if (fileType === BACKGROUND_MEDIA.VIDEO && mediaWrapperRef.current && isCapture && isVideoLoaded) {
      const $video = mediaWrapperRef.current.querySelector('video') as HTMLVideoElement;
      const dataURL = getCapturedVideoDataURL($video, mediaWrapperRef.current);
      addCapturedVideoImageToMediaWrapper(dataURL, mediaWrapperRef.current);
    }

    function getCapturedVideoDataURL($video: HTMLVideoElement, $mediaWrapper: HTMLDivElement) {
      const $canvas = document.createElement('canvas');
      $canvas.width = $video.videoWidth;
      $canvas.height = $video.videoHeight;

      $canvas.getContext('2d')!.drawImage($video, 0, 0, $video.videoWidth, $video.videoHeight);
      $mediaWrapper.removeChild($video);
      $mediaWrapper.appendChild($canvas);
      const dataURL = $canvas.toDataURL('image/png');
      $mediaWrapper.removeChild($canvas);

      return dataURL;
    }

    function addCapturedVideoImageToMediaWrapper(dataURL: string, $mediaWrapper: HTMLDivElement) {
      const $capturedImage = document.createElement('div');
      $capturedImage.className = cx('media', 'capture');
      $capturedImage.style.backgroundImage = `url('${dataURL}')`;
      $capturedImage.style.width = width;
      $capturedImage.style.height = height;
      $capturedImage.style.aspectRatio = aspectRatio;

      $capturedImage.style.position = 'absolute';
      $capturedImage.style.top = '0';
      $mediaWrapper.appendChild($capturedImage);
    }
  }, [isVideoLoaded]);

  const getMedia = () => {
    switch (fileType) {
      case BACKGROUND_MEDIA.IMAGE:
        return isCapture ? (
          <div
            className={cx('media', 'capture')}
            style={{
              backgroundImage: `url(${fileUrl})`,
            }}
          />
        ) : (
          <img className={cx('media')} src={fileUrl} alt={getAltText()} />
        );
      case BACKGROUND_MEDIA.VIDEO:
        return isCapture ? (
          <video className={cx('media')} crossOrigin={'anonymous'} onLoadedData={onLoadedVideoData} autoPlay>
            <source src={fileUrl} />
          </video>
        ) : (
          <video className={cx('media')} src={fileUrl} autoPlay loop muted playsInline />
        );
      default:
        return null;
    }
  };

  const bannerText = useMemo(() => {
    if (bannerType === BANNER_TYPE.BASIC) return banner.bannerBasic?.text ?? '';
    if (bannerType === BANNER_TYPE.SLIDE) return banner.bannerSlide.slideImageList[slideIndex!]?.text ?? '';
    return '';
  }, [banner, bannerType, slideIndex]);

  if (!fileUrl) return null;
  if (!shouldRender) return null;

  return (
    <div
      className={cx('wrapper', viewMode)}
      style={{
        width,
        height,
        aspectRatio,
        borderRadius: getBorderRadius(),
      }}
    >
      <div className={cx('imageSizeWrap', ratioType, { cursor: !!onClick })} onClick={() => onClick?.()}>
        <div
          className={cx('media-wrapper', viewMode, { correctLargeMobile: isLargeMobileMedia, hover: !!onClick })}
          ref={mediaWrapperRef}
        >
          {getMedia()}
        </div>
        <div
          className={cx('overlay')}
          style={{ backgroundColor: `${bannerStyle?.overlayColor}${aToHex((bannerStyle?.overlayRatio ?? 0) / 100)}` }}
        />
        {bannerText && (
          <ViewBannerText
            value={bannerText}
            viewMode={viewMode}
            viewLayout={viewLayout}
            isPreview={isPreview}
            options={options}
          />
        )}
      </div>
    </div>
  );
};

export default ViewBannerImage;
