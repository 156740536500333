import { FC, useMemo } from 'react';
import IViewLayout from '../../../interface/layout/IViewLayout';
import { LAYOUT_OPTION } from '../../../interface/layout/ILayout';
import styles from './ViewLayoutBackground.module.scss';
import classNames = require('classnames/bind');
import LayoutBackgroundColor from './LayoutBackgroundColor';
import LayoutBackgroundImage from './LayoutBackgroundImage';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { DefaultBorder } from '../../../interface/common/Border';
import { EDITOR_BLOCK_WIDTH } from '../../../constants/editor/block.constant';
import { Ratio } from '../../../interface/common/Ratio';

const cx = classNames.bind(styles);

export interface IViewLayoutBackgroundProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
}
const ViewLayoutBackground: FC<IViewLayoutBackgroundProps> = ({ viewLayout, viewMode, isCapture }) => {
  const { fileUrl } = viewLayout?.style?.desktopImageOffset ?? { fileUrl: '' };

  if (viewLayout?.style.backgroundType === LAYOUT_OPTION.COLOR)
    return <LayoutBackgroundColor viewLayout={viewLayout} />;

  if (viewLayout?.style.backgroundType === LAYOUT_OPTION.IMAGE && fileUrl)
    return <LayoutBackgroundImage viewLayout={viewLayout} viewMode={viewMode} isCapture={isCapture} />;

  return null;
};

const ViewLayoutBackgroundWrapper = (props: IViewLayoutBackgroundProps) => {
  const { viewLayout, viewMode } = props;
  const style = viewLayout.style;

  const hasBorderRadius = () => {
    const { leftDownRadius, rightTopRadius, rightDownRadius, leftTopRadius } =
      viewLayout.style?.borderRadius ?? DefaultBorder;
    return leftTopRadius || rightTopRadius || rightDownRadius || leftDownRadius;
  };
  const getBorderRadius = () => {
    if (!hasBorderRadius()) return undefined;
    const { leftDownRadius, rightTopRadius, rightDownRadius, leftTopRadius } =
      viewLayout.style?.borderRadius ?? DefaultBorder;
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  };

  const width = useMemo(() => {
    const DEFAULT_MOBILE_PADDING = 24;
    if (style.ratioType === Ratio.FULL) return '100%';
    if (viewMode === VIEW_MODE.MOBILE) return `calc(100% - ${DEFAULT_MOBILE_PADDING * 2}px)`;
    return viewLayout.style.width ?? EDITOR_BLOCK_WIDTH;
  }, [style, viewMode, viewLayout.style.width]);

  return (
    <div className={cx('background-wrapper')}>
      <div
        className={cx('background', viewLayout.style.ratioType)}
        style={{
          borderRadius: getBorderRadius(),
          overflow: hasBorderRadius() ? 'hidden' : 'visible',
          width,
        }}
      >
        <ViewLayoutBackground {...props} />
      </div>
    </div>
  );
};

export default ViewLayoutBackgroundWrapper;
