import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { IMap } from 'ui/interface/contents/map/IMap';
import { CoordinateType } from 'ui/interface/contents/map/IMap.constant';
import useToast from '../../../../context/toast/useToast';
import { getMarkerSVG } from '../createCustomMaker';
import useDebounce from 'ui/hook/useDebounce';
import { ViewModeType } from '../../../../interface/common/ViewMode';

interface IProps {
  mapContent: IMap;
  cx: (...args: classNames.ArgumentArray) => string;
  viewMode?: ViewModeType;
  renderDependency?: unknown;
}

const useMap = ({ mapContent, cx, viewMode, renderDependency }: IProps) => {
  const { setToast } = useToast();

  const { value, style } = mapContent;
  const { latitude, longitude, address, detailAddress } = value;
  const { sizePercentage, markerColor, markerName } = style;
  const encodedText = encodeURIComponent(address);

  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /** 주소가 없거나 카카오맵이 로드되지 않았다면 return; */
    if (!address || !window.kakao || !mapContainerRef.current || !window.kakao?.maps?.LatLng) return;

    const { kakao } = window;

    /** 마커 그리기 */
    const createCustomMarker = ({ map, coordinate }: { map: unknown; coordinate: CoordinateType }) => {
      const [latitude, longitude] = coordinate;

      const imageSrc = getMarkerSVG(markerColor);
      const imageSize = new kakao.maps.Size(62, 62);
      const imageOption = { offset: new kakao.maps.Point(31, 60) };

      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
      const markerPosition = new kakao.maps.LatLng(latitude, longitude);

      const marker = new kakao.maps.Marker({
        position: markerPosition,
        image: markerImage,
      });

      marker.setMap(map);
      kakao.maps.event.addListener(marker, 'click', function () {
        window.open(`https://map.kakao.com/?q=${encodedText}`, '_blank');
      });
    };

    /** 말풍선 그리기 */
    const createCustomOverlay = ({ map, coordinate }: { map: unknown; coordinate: CoordinateType }) => {
      const [latitude, longitude] = coordinate;

      const OverlayContent = () => (
        <a
          href={`https://map.kakao.com/?q=${encodedText}`}
          target={'_blank'}
          className={cx('overlay')}
          id={'overlayContainer'}
          onClick={(e) => e.stopPropagation()}
          rel={'noreferrer'}
          style={{ '--pin-color': markerColor } as React.CSSProperties}
        >
          <span className={cx('overlay-text')}>{markerName}</span>
        </a>
      );

      const overlayContent = renderToString(<OverlayContent />);
      const overlayPosition = new kakao.maps.LatLng(latitude, longitude);

      new kakao.maps.CustomOverlay({
        map: map,
        position: overlayPosition,
        content: overlayContent,
      });
    };

    /** 지도 그리기 */
    const createMap = () => {
      // 지도 중심 좌표를 살짝 아래로 내려서, 4단(높이가 낮을 경우)에도 마커와 말풍선이 한눈에 보이도록 함
      const MAP_CENTER_OFFSET = 0.0003;
      const options = {
        center: new kakao.maps.LatLng(latitude + MAP_CENTER_OFFSET, longitude),
        level: 3,
      };

      const map = new kakao.maps.Map(mapContainerRef.current as HTMLElement, options);

      createCustomMarker({ map, coordinate: [latitude, longitude] });

      if (markerName) {
        createCustomOverlay({ map, coordinate: [latitude, longitude] });
      }
    };

    createMap();

    const handleResize = () => {
      const debounceTimer = setTimeout(createMap, 300);
      return () => clearTimeout(debounceTimer);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    address,
    markerName,
    markerColor,
    sizePercentage,
    window.kakao,
    window.kakao?.maps?.LatLng,
    viewMode,
    renderDependency,
  ]);

  const handleClickCopyIcon = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const textToCopy = `${address}, ${detailAddress}`;
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setToast({ icon: 'POSITIVE', text: '주소가 복사되었습니다.' });
    } catch (error) {
      setToast({ icon: 'FAILED', text: '주소 복사에 실패했습니다.' });
    }
  };

  return {
    mapContainerRef,
    handleClickCopyIcon,
  };
};

export default useMap;
