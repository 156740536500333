import { OnOffType } from '../../../constants/common';
import { Nullable } from '../../common/common';

export const RECRUITMENT_VIEW = {
  LIST: 'LIST',
  KANBAN: 'KANBAN',
  SIDE_FILTER: 'SIDE_FILTER',
} as const;

export type TRecruitmentView = (typeof RECRUITMENT_VIEW)[keyof typeof RECRUITMENT_VIEW];

export interface IRecruitmentStyle {
  viewType: Nullable<TRecruitmentView>;
  badgeColor: Nullable<string>;
  badgeTextColor: Nullable<string>;
  badgeBrandColorToggle: OnOffType;
  badgeTextBrandColorToggle: OnOffType;
}
