const useRecruitSideSettingAccordion = () => {
  const handleToggleFilterOption = <T>({
    selectedValue,
    selectedValueList,
    setSelectedValueList,
  }: {
    selectedValue: T;
    selectedValueList: T[];
    setSelectedValueList?: (selectedValueList: T[]) => void;
  }) => {
    if (!setSelectedValueList) return;

    const optionIsTotalOption = !selectedValue;

    if (optionIsTotalOption) {
      setSelectedValueList([]);
      return;
    }

    const newSelectedOptionValues = selectedValueList.includes(selectedValue)
      ? selectedValueList.filter((v) => v !== selectedValue)
      : [...selectedValueList, selectedValue];
    setSelectedValueList(newSelectedOptionValues);
  };

  return {
    handleToggleFilterOption,
  };
};

export default useRecruitSideSettingAccordion;
