import { ReactNode } from 'react';
import styles from './TooltipWrapper.module.scss';
import classNames from 'classnames/bind';
import { TailPositions } from '../index';

const cx = classNames.bind(styles);

interface TooltipWrapperProps {
  children?: ReactNode;
  className?: string;
  tailPosition?: TailPositions;
}

const _TooltipWrapper = ({ children, className, tailPosition = 'left' }: TooltipWrapperProps) => {
  return (
    <div className={cx('tooltipWrapper', className)} data-designsystem>
      <div className={cx('tooltipBox', tailPosition)}>{children}</div>
    </div>
  );
};

export default _TooltipWrapper;
