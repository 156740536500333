import { FC, useMemo } from 'react';
import { isOnOff, OFF, ON, OnOffType } from '../../../constants/common';
import Icon from '../../../common/icon/Icon';
import style from './BannerSlideNavigator.module.scss';
import classnames from 'classnames/bind';
import { COLORS } from '../../../constants/colors';

const cx = classnames.bind(style);
export const MAX_BANNER_SLIDE_COUNT = 9;

export interface IBannerSlideNavigatorProps {
  shouldRender?: boolean;
  count: number;
  maxCount: number;
  onClickPrev: () => void;
  isPrevDisabled: boolean;
  onClickNext: () => void;
  isNextDisabled: boolean;
  renderAutoSwitch: boolean;
  autoSwitchToggle: OnOffType;
  onChangeAutoSwitchToggle?: (value: OnOffType) => void;
  isEdit?: boolean;
}
const BannerSlideNavigator: FC<IBannerSlideNavigatorProps> = (props) => {
  const {
    shouldRender = true,
    count,
    maxCount,
    onClickNext,
    isNextDisabled: _isNextDisabled,
    onClickPrev,
    isPrevDisabled,
    isEdit,

    renderAutoSwitch,
    autoSwitchToggle,
    onChangeAutoSwitchToggle,
  } = props;

  const isNextDisabled = useMemo(() => {
    if (isEdit) return count === MAX_BANNER_SLIDE_COUNT;
    return _isNextDisabled;
  }, [isEdit, count, _isNextDisabled]);

  if (!shouldRender) return null;

  return (
    <div className={cx('container')}>
      <div
        className={cx('navigator', { disabled: isPrevDisabled })}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isPrevDisabled) return;
          onClickPrev();
        }}
      >
        <Icon color={COLORS.white} name={'back_line'} size={24} />
      </div>
      <div className={cx('indicator')}>
        <div className={cx('indicator__count')}>
          <span>{count}</span>
          <span>･</span>
          <span>{maxCount}</span>
        </div>
        {renderAutoSwitch && (
          <div className={cx('indicator__auto-switch')}>
            {isOnOff(autoSwitchToggle) ? (
              <Icon
                onClick={() => onChangeAutoSwitchToggle?.(OFF)}
                color={COLORS.white}
                name={'pause_filled'}
                size={24}
              />
            ) : (
              <Icon
                onClick={() => onChangeAutoSwitchToggle?.(ON)}
                color={COLORS.white}
                name={'play_l_line'}
                size={24}
              />
            )}
          </div>
        )}
      </div>
      <div
        className={cx('navigator', { disabled: isNextDisabled })}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isNextDisabled) return;
          onClickNext();
        }}
      >
        <Icon color={COLORS.white} name={'next_line'} size={24} />
      </div>
    </div>
  );
};

export default BannerSlideNavigator;
