import { FC } from 'react';
import IViewGroup from '../../../interface/group/IViewGroup';

interface IProps {
  viewGroup: IViewGroup;
}
const GroupBackgroundColor: FC<IProps> = ({ viewGroup }) => {
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: viewGroup.style?.backgroundColor ?? undefined }} />
  );
};

export default GroupBackgroundColor;
