import { useState } from 'react';
import { SolutionType } from '../../../constants/solution/solution.constant';
import KeywordFilter from '../recruitSetting/keywordFilter/keywordFilter';
import style from './RecruitSideSettingSearchArea.module.scss';

import classNames from 'classnames/bind';
import Button from '../../../common/button/Button';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { ViewModeType } from '../../../interface/common/ViewMode';
const cx = classNames.bind(style);

interface IProps {
  solution: SolutionType;
  viewMode?: ViewModeType;
  keyword: string;
  handleInputKeyword?: (value: string) => void;
  handleClickReset: () => void;
  readOnly?: boolean;
}

const RecruitSideSettingSearchArea = ({
  solution,
  viewMode,
  keyword,
  handleInputKeyword,
  handleClickReset,
  readOnly,
}: IProps) => {
  /**
   *   keywordInput: 자음이나 모음만 있을 땐 검색이 안되도록 하기위해 view를 위한 데이터
   *   keyword: 실제 검색에 사용되는 데이터
   */
  const [keywordInput, setKeywordInput] = useState('');

  const handleInputKeywordInput = (text: string) => {
    setKeywordInput(text);
  };

  const handleReset = () => {
    handleInputKeywordInput('');
    handleClickReset();
  };

  const { multiLanguageText } = useMultiLanguage();

  return (
    <div className={cx('container')}>
      <div className={cx('title-section')}>
        <span className={cx('title')}>{multiLanguageText('검색', 'Search')}</span>
        <Button
          leftIcon={'reset_line'}
          variant={'text'}
          size={'sm'}
          className={cx('reset', viewMode)}
          onClick={handleReset}
        >
          {multiLanguageText('초기화', 'Reset')}
        </Button>
      </div>
      <KeywordFilter
        solution={solution}
        viewMode={viewMode}
        keyword={keyword}
        keywordInput={keywordInput}
        handleInputKeyword={handleInputKeyword}
        handleInputKeywordInput={handleInputKeywordInput}
        disabled={readOnly}
      />
    </div>
  );
};

export default RecruitSideSettingSearchArea;
