import dayjs from 'dayjs';
import { LANGUAGE } from '../../../constants/language';
import { CAREER_DEFINITION, RECRUITMENT_TYPE_DEFINITION } from '../../../constants/position/common';
import { SOLUTION } from '../../../constants/solution/solution.constant';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { Nullable } from '../../../interface/common/common';
import { IRecruit } from '../../../interface/recruit/IRecruitList';
import { ITag } from '../../../interface/recruit/IRecruitSetting';
import { SOLUTION_TYPE } from '../../../util/solution';

const textRepresentItemOfList = (list: string[], unit = '개'): string => {
  const len = list.length;

  if (len === 0) {
    return '';
  } else if (len === 1) {
    return list[0];
  }
  return `${list[0]} 외 ${len - 1}${unit}`;
};

const removeDuplicate = (arr: string[]): string[] => {
  return Array.from(new Set(arr));
};

const useGetRecruitItemText = () => {
  const { multiLanguageText, languageType } = useMultiLanguage();
  const isKorean = languageType === LANGUAGE.KOR;

  const getRecruitFilterTextList = (recruitment: IRecruit): string[] => {
    const {
      workingAreaName,
      workingAreaEnglishName,
      jobGroupName,
      jobGroupEnglishName,
      careerType,
      classificationCode,
      recruitmentType,
    } = recruitment;

    switch (SOLUTION_TYPE) {
      case SOLUTION.JOBDA:
        const result = isKorean
          ? [textRepresentItemOfList(workingAreaName), jobGroupName, CAREER_DEFINITION[careerType][languageType]]
          : [
              textRepresentItemOfList(workingAreaEnglishName || workingAreaName),
              jobGroupEnglishName || jobGroupName,
              careerType && CAREER_DEFINITION[careerType][languageType],
            ];

        return removeDuplicate(result);
      case SOLUTION.JOBFLEX:
        return removeDuplicate(
          [
            isKorean && classificationCode,
            recruitmentType && RECRUITMENT_TYPE_DEFINITION[recruitmentType][languageType],
            careerType && CAREER_DEFINITION[careerType][languageType],
          ].filter(Boolean) as string[]
        );
    }
  };

  const getRecruitTagText = (tagList: ITag[]): { summaryText: string; fullText: Nullable<string> } => {
    const summaryText = textRepresentItemOfList(tagList.map((tag) => tag.tagName));
    const fullText = tagList.length > 1 ? tagList.map((tag) => tag.tagName).join(', ') : null;
    return { summaryText, fullText };
  };

  const getDateText = (startDateTime: string, endDateTime: string): string => {
    const startDateTimeText = startDateTime ? dayjs(startDateTime).format('YY.MM.DD') : '';
    const endDateTimeText = endDateTime ? dayjs(endDateTime).format('YY.MM.DD HH:mm') : '영입 종료 시';
    return `${startDateTimeText}~${endDateTimeText}`;
  };

  const checkIsAlways = (recruitment: IRecruit): boolean => {
    const isAlways =
      (SOLUTION_TYPE === SOLUTION.JOBDA && recruitment.announcementType === 'ALWAYS') ||
      (SOLUTION_TYPE === SOLUTION.JOBFLEX && recruitment.recruitmentType === 'PERMANENT');

    return isAlways;
  };

  const getDdayText = (recruitment: IRecruit): string => {
    const { dday, endDateTime } = recruitment;

    const now = new Date();

    const isAlways = checkIsAlways(recruitment);
    if (isAlways) {
      return multiLanguageText('상시', 'Ongoing');
    }

    const isClosed = new Date(endDateTime) < now;
    if (isClosed) {
      return multiLanguageText('마감', 'Closed');
    }

    return dday === 0 ? 'D-Day' : `D-${dday}`;
  };

  return { checkIsAlways, getRecruitFilterTextList, getRecruitTagText, getDateText, getDdayText };
};

export default useGetRecruitItemText;
