import React, { FC } from 'react';
import { ViewModeType } from '../../interface/common/ViewMode';
import classnames from 'classnames/bind';
import styles from './ViewLayout.module.scss';
import IViewLayout from '../../interface/layout/IViewLayout';
import { LAYOUT } from '../../interface/layout/ILayout';
import ViewBlock from '../block/ViewBlock';
import IPageStyle from '../../interface/page/IPageStyle';
import ViewBanner from './banner/ViewBanner';
import { HasOption } from '../../interface/common/hasOption';
import { COLORS } from '../../constants/colors';
import ViewSlider from './slider/ViewSlider';
import ViewLayoutWrapper from './ViewLayoutWrapper';
import ViewLayoutDefault from './default/ViewLayoutDefault';
const cx = classnames.bind(styles);

interface IViewLayoutProps {
  viewMode?: ViewModeType;
  pageStyle: IPageStyle;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
  isLast: boolean;
}

const ViewLayout: FC<IViewLayoutProps & HasOption> = ({
  viewMode,
  pageStyle,
  viewLayout,
  isCapture = false,
  isPreview,
  isEdit,
  previewTabType,
  options,
  isLast,
}) => {
  const LayoutElement = (viewLayout: IViewLayout) => {
    const { type } = viewLayout;

    switch (type) {
      case LAYOUT.BANNER:
        return (
          <ViewBanner
            viewMode={viewMode}
            viewLayout={viewLayout}
            isCapture={isCapture}
            options={options}
            isPreview={isPreview}
            isEdit={isEdit}
          />
        );
      case LAYOUT.SLIDER:
        return (
          <ViewSlider
            viewMode={viewMode}
            viewLayout={viewLayout}
            isCapture={isCapture}
            options={options}
            isPreview={isPreview}
            isEdit={isEdit}
          />
        );
      case LAYOUT.EMPTY:
        return null;
      default:
        return (
          <ViewLayoutDefault
            viewMode={viewMode}
            pageStyle={pageStyle}
            isCapture={isCapture}
            isPreview={isPreview}
            isEdit={isEdit}
            previewTabType={previewTabType}
            options={options}
            viewLayout={viewLayout}
          />
        );
    }
  };

  return (
    <ViewLayoutWrapper
      viewLayout={viewLayout}
      isLast={isLast}
      viewMode={viewMode}
      isPreview={isPreview}
      previewTabType={previewTabType}
    >
      {LayoutElement(viewLayout)}
    </ViewLayoutWrapper>
  );
};

export default ViewLayout;
