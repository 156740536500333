import IViewLayout from '../../../interface/layout/IViewLayout';
import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewBanner.module.scss';
import { ViewModeType } from '../../../interface/common/ViewMode';
import { HasOption } from '../../../interface/common/hasOption';
import ViewLayoutBackground from '../ViewLayoutBackground/ViewLayoutBackground';
import { BANNER_TYPE } from '../../../interface/layout/banner/banner.constant';
import ViewBannerBasic from './ViewBannerBasic/ViewBannerBasic';
import ViewBannerSlide from './ViewBannerSlide/ViewBannerSlide';
const cx = classnames.bind(styles);

interface IViewBannerProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewBanner: FC<IViewBannerProps & HasOption> = ({
  viewMode,
  viewLayout,
  isCapture,
  isPreview,
  isEdit,
  options,
}) => {
  return (
    <div id={viewLayout.id} className={cx('container')}>
      {viewLayout.banner?.type === BANNER_TYPE.BASIC ? (
        <ViewBannerBasic
          viewMode={viewMode}
          viewLayout={viewLayout}
          isCapture={isCapture}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      ) : (
        <ViewBannerSlide
          viewMode={viewMode}
          viewLayout={viewLayout}
          isCapture={isCapture}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      )}
      <ViewLayoutBackground viewLayout={viewLayout} viewMode={viewMode} isCapture={isCapture} />
    </div>
  );
};

export default ViewBanner;
