import { FC, useMemo } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import classnames from 'classnames/bind';
import styles from './ViewBannerColor.module.scss';
import ViewBannerText from './ViewBannerText';
import { Ratio } from '../../../interface/common/Ratio';
import { HasOption } from '../../../interface/common/hasOption';
import { COLORS } from '../../../constants/colors';
import { IBannerDesktopStyle } from '../../../interface/layout/banner/IBannerDesktopStyle';
import { IBannerMobileStyle } from '../../../interface/layout/banner/IBannerMobileStyle';
import { DefaultBorder } from '../../../interface/common/Border';
import { BANNER_TYPE } from '../../../interface/layout/banner/banner.constant';
const cx = classnames.bind(styles);

interface IViewBannerColorProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isPreview?: boolean;
  onClick?: () => void;
}

const DEFAULT_BANNER_HEIGHT = 720;

const ViewBannerColor: FC<IViewBannerColorProps & HasOption> = ({
  viewMode,
  viewLayout,
  isPreview,
  onClick,
  options,
}) => {
  const banner = viewLayout.banner!;
  const bannerType = banner.type;

  const bannerStyle = useMemo<IBannerDesktopStyle | IBannerMobileStyle | undefined>(() => {
    if (bannerType === BANNER_TYPE.BASIC && viewMode === VIEW_MODE.MOBILE) return banner?.bannerBasic.mobileStyle;
    if (bannerType === BANNER_TYPE.BASIC && viewMode === VIEW_MODE.PC) return banner?.bannerBasic.desktopStyle;
    if (bannerType === BANNER_TYPE.SLIDE && viewMode === VIEW_MODE.MOBILE) return banner?.bannerSlide.mobileStyle;
    if (bannerType === BANNER_TYPE.SLIDE && viewMode === VIEW_MODE.PC) return banner?.bannerSlide.desktopStyle;
  }, [viewLayout, viewMode]);

  const color = banner?.bannerBasic.color ?? COLORS.white;

  const width = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE) return `${(bannerStyle as IBannerMobileStyle)?.sizePercentage}%`;
    if ((bannerStyle as IBannerDesktopStyle)?.ratioType === Ratio.FULL) return '100%';
    return `${(bannerStyle as IBannerDesktopStyle)?.width}px`;
  }, [viewLayout, viewMode]);

  const height = useMemo(() => {
    return (bannerStyle as IBannerDesktopStyle)?.height ?? DEFAULT_BANNER_HEIGHT;
  }, [viewLayout, viewMode]);

  const ratioType = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE) return undefined;
    return (bannerStyle as IBannerDesktopStyle)?.ratioType;
  }, [viewLayout, viewMode]);

  const getBorderRadius = () => {
    const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } =
      bannerStyle?.borderRadius ?? DefaultBorder;
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  };

  return (
    <div className={cx('wrapper')} style={{ width, height, borderRadius: getBorderRadius() }}>
      <div
        className={cx('banner', ratioType, { cursor: !!onClick })}
        style={{
          backgroundColor: color,
        }}
        onClick={onClick}
      >
        <ViewBannerText
          value={banner.bannerBasic.text ?? []}
          viewMode={viewMode}
          viewLayout={viewLayout}
          isPreview={isPreview}
          options={options}
        />
      </div>
    </div>
  );
};

export default ViewBannerColor;
