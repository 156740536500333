import { createContext, FC, ReactNode, useState } from 'react';
import { IFont } from '../../constants/font.constant';

interface IFontInjectedContextValueProps {
  children: ReactNode;
}

interface IFontInjectedContextValue {
  fontList: IFont[];
  setFontList: (fontList: IFont[]) => void;
}

export const initialFontInjectedContextValue: IFontInjectedContextValue = {
  fontList: [],
  setFontList: () => {},
};

export const FontInjectedContext = createContext<IFontInjectedContextValue>(initialFontInjectedContextValue);

export const FontInjectedContextProvider: FC<IFontInjectedContextValueProps> = ({ children }) => {
  const [fontList, setFontList] = useState<IFont[]>([]);

  return <FontInjectedContext.Provider value={{ fontList, setFontList }}>{children}</FontInjectedContext.Provider>;
};
