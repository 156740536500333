import RecruitSideSettingAccordion from './RecruitSideSettingAccordion';

import style from './RecruitSideSetting.module.scss';

import classNames from 'classnames/bind';
import { CAREER_DEFINITION, FILTER_DEFINITION, SUBMISSION_STATUS_DEFINITION } from '../../../constants/position/common';
import { SOLUTION, SolutionType } from '../../../constants/solution/solution.constant';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { ViewModeType } from '../../../interface/common/ViewMode';
import { TCareerType, TSubmissionStatusType } from '../../../interface/recruit/IRecruitSetting';
import {
  IRecruitSettingSelectedList,
  IRecruitSettingSelectorList,
  IRecruitSettingSetSelectedList,
} from '../recruitSetting/RecruitSetting';
import RecruitSideSettingSearchArea from './RecruitSideSettingSearchArea';
import useRecruitSideSettingAccordion from './useRecruitSideSettingAccordion';
const cx = classNames.bind(style);

export interface IRecruitSideSettingTagFilterProps {
  isEditable: boolean;
  isEditing: boolean;
  inputValue: string;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickEditIcon: (e: React.MouseEvent) => void;
  changeToReadMode: () => void;
}

interface IRecruitSideSettingProps {
  tagFilterNameProps?: IRecruitSideSettingTagFilterProps;
  solution?: SolutionType;
  viewMode?: ViewModeType;
  selectorList?: IRecruitSettingSelectorList;
  selectedList?: IRecruitSettingSelectedList;
  setSelectedList?: IRecruitSettingSetSelectedList;
  readOnly?: boolean;
}

const RecruitSideSetting = ({
  tagFilterNameProps,
  solution = SOLUTION.JOBDA,
  viewMode = 'PC',
  selectorList,
  selectedList,
  setSelectedList,
  readOnly = false,
}: IRecruitSideSettingProps) => {
  const {
    tagOptionList = [],
    filterTypeList = [],
    jobGroupList = [],
    careerTypeList = [],
    workingAreaList = [],
    submissionStatusList = [],
  } = selectorList || {};

  const {
    keyword = '',
    tag: selectedTagList = [],
    jobGroup: selectedJobGroupList = [],
    workingArea: selectedWorkingAreaList = [],
    careerType: selectedCareerTypeList = [],
    submissionStatus: selectedSubmissionStatusList = [],
  } = selectedList || {};

  const {
    keyword: setKeyword,
    tag: setSelectedTagList,
    jobGroup: setSelectedJobGroupList,
    workingArea: setSelectedWorkingAreaList,
    careerType: setSelectedCareerTypeList,
    submissionStatus: setSelectedSubmissionStatusList,
  } = setSelectedList || {};

  const { multiLanguageText, languageType } = useMultiLanguage();
  const { handleToggleFilterOption } = useRecruitSideSettingAccordion();

  const handleClickReset = () => {
    setKeyword?.('');
    setSelectedTagList?.([]);
    setSelectedJobGroupList?.([]);
    setSelectedCareerTypeList?.([]);
    setSelectedWorkingAreaList?.([]);
    setSelectedSubmissionStatusList?.([]);
  };

  return (
    <div className={cx('side-setting-container')}>
      <RecruitSideSettingSearchArea
        solution={solution}
        viewMode={viewMode}
        keyword={keyword}
        handleInputKeyword={setKeyword}
        handleClickReset={handleClickReset}
        readOnly={readOnly}
      />

      {/* 태그 */}
      <RecruitSideSettingAccordion
        title={multiLanguageText('태그', 'Tag')}
        options={(tagOptionList ?? []).map(({ value, label }) => ({
          label: String(label),
          value,
        }))}
        selectedOptionValues={selectedTagList}
        handleClickOption={(option) => {
          handleToggleFilterOption<string>({
            selectedValue: String(option.value),
            selectedValueList: selectedTagList,
            setSelectedValueList: setSelectedTagList,
          });
        }}
        tagFilterNameProps={tagFilterNameProps}
      />

      {/* 직군 */}
      {filterTypeList.includes('JOB_GROUP_AND_JOB') && (
        <RecruitSideSettingAccordion
          title={FILTER_DEFINITION['JOB_GROUP_AND_JOB'][languageType]}
          options={(jobGroupList ?? []).map((item) => ({
            label: multiLanguageText(item.name, item.englishName || item.name),
            value: item.sn,
          }))}
          selectedOptionValues={selectedJobGroupList}
          handleClickOption={(option) => {
            handleToggleFilterOption<number>({
              selectedValue: Number(option.value),
              selectedValueList: selectedJobGroupList,
              setSelectedValueList: setSelectedJobGroupList,
            });
          }}
        />
      )}

      {/* 신입/경력 */}
      {filterTypeList.includes('CAREER_TYPE') && (
        <RecruitSideSettingAccordion
          title={FILTER_DEFINITION['CAREER_TYPE'][languageType]}
          options={(careerTypeList ?? []).map((item) => ({
            label: CAREER_DEFINITION[item][languageType],
            value: item,
          }))}
          selectedOptionValues={selectedCareerTypeList}
          handleClickOption={(option) => {
            handleToggleFilterOption<TCareerType>({
              selectedValue: option.value as TCareerType,
              selectedValueList: selectedCareerTypeList,
              setSelectedValueList: setSelectedCareerTypeList,
            });
          }}
        />
      )}

      {/* 근무지역 */}
      {filterTypeList.includes('WORKING_AREA') && (
        <RecruitSideSettingAccordion
          title={FILTER_DEFINITION['WORKING_AREA'][languageType]}
          options={(workingAreaList ?? []).map((item) => ({
            label: multiLanguageText(item.name, item.englishName || item.name),
            value: item.sn,
          }))}
          selectedOptionValues={selectedWorkingAreaList}
          handleClickOption={(option) => {
            handleToggleFilterOption<number>({
              selectedValue: Number(option.value),
              selectedValueList: selectedWorkingAreaList,
              setSelectedValueList: setSelectedWorkingAreaList,
            });
          }}
        />
      )}

      {/* 접수상태 */}
      {filterTypeList.includes('SUBMISSION_STATUS') && (
        <RecruitSideSettingAccordion
          title={FILTER_DEFINITION['SUBMISSION_STATUS'][languageType]}
          options={(submissionStatusList ?? []).map((value) => ({
            label: SUBMISSION_STATUS_DEFINITION[value][languageType],
            value: value,
          }))}
          selectedOptionValues={selectedSubmissionStatusList}
          handleClickOption={(option) => {
            handleToggleFilterOption<TSubmissionStatusType>({
              selectedValue: option.value as TSubmissionStatusType,
              selectedValueList: selectedSubmissionStatusList,
              setSelectedValueList: setSelectedSubmissionStatusList,
            });
          }}
        />
      )}
    </div>
  );
};

export default RecruitSideSetting;
