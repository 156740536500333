import { Descendant } from 'slate';
import { OnOffType } from '../../../constants/common';

export const IMAGE_TEXT_LOCATION_TYPE = { TOP: 'TOP', CENTER: 'CENTER', BOTTOM: 'BOTTOM' } as const;
export type ImageTextLocationType = (typeof IMAGE_TEXT_LOCATION_TYPE)[keyof typeof IMAGE_TEXT_LOCATION_TYPE];

export const IMAGE_HOVER_ACTIVE_TYPE = { ALWAYS: 'ALWAYS', MOUSE_OVER: 'MOUSE_OVER' } as const;
export type ImageHoverActiveType = (typeof IMAGE_HOVER_ACTIVE_TYPE)[keyof typeof IMAGE_HOVER_ACTIVE_TYPE];
export interface IImageHover {
  hoverToggle?: OnOffType;
  text?: Descendant[];
  textLocationType?: ImageTextLocationType;
  overlayColor?: string;
  overlayRatio?: number;
  activeType?: ImageHoverActiveType;
}

export type IImageHoverDTO = Omit<IImageHover, 'text'> & {
  text: string;
};
