import { ISliderStyle } from './sliderStyle/ISliderStyle';
import { ISliderItem } from './sliderItem/ISliderItem';

export const COLUMN = {
  COLUMN1: 'COLUMN1',
  COLUMN2: 'COLUMN2',
  COLUMN3: 'COLUMN3',
  COLUMN4: 'COLUMN4',
} as const;

export const COLUMN_NUMBER = {
  COLUMN1: 1,
  COLUMN2: 2,
  COLUMN3: 3,
  COLUMN4: 4,
};

export type ColumnType = (typeof COLUMN)[keyof typeof COLUMN];

interface ISlider {
  columnType: ColumnType; // 슬라이더 몇단인지
  style: ISliderStyle; // 이미지 스타일
  items: ISliderItem[]; // 슬라이더 목록
}

export default ISlider;
