import { OnOffType } from '../../../../constants/common';

export const DIRECTION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const;

export type DirectionType = (typeof DIRECTION)[keyof typeof DIRECTION];

export interface IAutoSlide {
  autoMode: OnOffType;
  direction: DirectionType;
}
