import { AxiosError } from 'axios';
import { UseMutateFunction } from '@tanstack/react-query';
import {
  IPostThumbnailPostListV1Request,
  IPostThumbnailPostListV1Response,
} from '../../../interface/contents/board/getThumbnailPostListV1';
import { useContext, useEffect, useState } from 'react';
import IViewContents from '../../../interface/contents/IViewContents';
import { IBoard } from '../../../interface/contents/board/IBoard';
import { DEFAULT_PAGE } from '../../../constants/common';
import BoardSettingContext from '../../../context/board/BoardSettingContext';
import { IThumbnailPost } from '../../../interface/contents/board/IPostList';
import { IPagination } from '../../../interface/common/common';
import { INITIAL_VIEW_BOARD_PROPS } from '../../../context/board/BoardInjectedContext';
import useSearchParamsExtended from '../../../hook/useSearchParamsExtended';

export type TPostThumbnailPostList = UseMutateFunction<
  IPostThumbnailPostListV1Response,
  AxiosError,
  IPostThumbnailPostListV1Request
>;

export interface IViewBoardProps {
  postThumbnailPostList?: TPostThumbnailPostList;
  mutatePostThumbnailPostListData?: IPostThumbnailPostListV1Response;
}

interface IProps {
  viewBoardProps: IViewBoardProps;
  viewBoard: IViewContents<IBoard>;
}

const useViewBoard = ({ viewBoardProps, viewBoard }: IProps) => {
  const { postThumbnailPostList, mutatePostThumbnailPostListData: mutatePostThumbnailPostListData } = viewBoardProps;
  const { selectedCategory, setSelectedCategory } = useContext(BoardSettingContext);
  const [articleList, setArticleList] = useState<IThumbnailPost[]>([]);
  const [articlePagination, setArticlePagination] = useState<IPagination>(
    INITIAL_VIEW_BOARD_PROPS.mutatePostThumbnailPostListData.pagination
  );

  useEffect(() => {
    if (!postThumbnailPostList) return;
    const categoryNameListForFilter = selectedCategory ? [selectedCategory] : viewBoard.value.categoryNameList;
    postThumbnailPostList({
      pageable: { page: DEFAULT_PAGE, size: viewBoard.value.size },
      filter: { categoryNameList: categoryNameListForFilter.length > 0 ? categoryNameListForFilter : null },
    });
  }, [viewBoard, postThumbnailPostList]);

  useEffect(() => {
    if (!mutatePostThumbnailPostListData) return;
    setArticleList(mutatePostThumbnailPostListData.list);
    setArticlePagination(mutatePostThumbnailPostListData.pagination);
  }, [mutatePostThumbnailPostListData]);

  const searchParams = useSearchParamsExtended();
  const articleSelectedCategory = searchParams.get('articleSelectedCategory');

  useEffect(() => {
    setSelectedCategory(articleSelectedCategory ?? '');
  }, [articleSelectedCategory]);

  return { postThumbnailPostList, mutatePostThumbnailPostListData, articleList, articlePagination };
};

export default useViewBoard;
