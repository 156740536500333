import { ILayout, LAYOUT, LAYOUT_OPTION, LayoutIdType } from 'ui/interface/layout/ILayout';
import IBlock, { Block, BlockIdType } from 'ui/interface/block/IBlock';
import { IContents, CONTENTS, ContentsIdType } from 'ui/interface/contents/IContents';
import IPage, { PageIdType } from 'ui/interface/page/IPage';
import IGroup, { GroupIdType } from 'ui/interface/group/IGroup';
import { v1 } from 'uuid';
import IPageStyle from 'ui/interface/page/IPageStyle';
import { BlockPaddingMap, PaddingType, EnumPadding, GroupPaddingMap, PaddingStyle } from 'ui/interface/common/Padding';
import { COLORS } from 'ui/constants/colors';
import { transparentize } from 'ui/util/color.utils';
import {
  DEFAULT_BLOCK_GAP,
  DEFAULT_CONTENTS_GROUP_GAP,
  DEFAULT_CONTENTS_GAP,
  DEFAULT_LAYOUT_MARGIN_BOTTOM,
} from 'ui/constants/editor/page.constant';
import { Ratio } from 'ui/interface/common/Ratio';
import { ILayoutStyle } from 'ui/interface/layout/ILayoutStyle';
import { IGroupImageStyle, IGroupStyle } from 'ui/interface/group/IGroupStyle';
import { DefaultLinkStyle } from 'ui/interface/common/Link';
import { DefaultBorder } from 'ui/interface/common/Border';
import { OFF } from 'ui/constants/common';
import { EDITOR_BLOCK_WIDTH } from 'ui/constants/editor/block.constant';
import { DEFAULT_OVERLAY_COLOR } from './commonDefaultEditorConfig';

export const ids = {
  page_0_id: `page_${v1()}` as PageIdType,
  layout_0_id: `layout_${v1()}` as LayoutIdType,
  layout_1_id: `layout_${v1()}` as LayoutIdType,
  block_0_id: `block_${v1()}` as BlockIdType,
  group_0_id: `group_${v1()}` as GroupIdType,
  contents_0_id: `contents_${v1()}` as ContentsIdType,
};

export const DEFAULT_PAGE_STYLE: IPageStyle = {
  isToggledGap: 'OFF',
  blockGap: DEFAULT_BLOCK_GAP,
  contentsGroupGap: DEFAULT_CONTENTS_GROUP_GAP,
  contentsGap: DEFAULT_CONTENTS_GAP,
  layoutMarginBottom: DEFAULT_LAYOUT_MARGIN_BOTTOM,
};

export const getDefaultPage = (id: PageIdType, layoutId: LayoutIdType, emptyLayoutId: LayoutIdType): IPage => {
  return {
    id: id,
    layoutIds: [layoutId, emptyLayoutId],
    style: DEFAULT_PAGE_STYLE,
  };
};

export const getDefaultLayoutStyle = (margin?: number): ILayoutStyle => ({
  backgroundType: LAYOUT_OPTION.IMAGE,
  backgroundColor: transparentize(COLORS.white),
  desktopImageOffset: null,
  mobileImageOffset: null,
  ratioType: Ratio.FULL,
  width: EDITOR_BLOCK_WIDTH,
  height: 0,
  margin: margin ?? 0,
  overlayColor: DEFAULT_OVERLAY_COLOR,
  overlayRatio: null,
  backgroundBrandColorToggle: OFF,
  borderRadius: DefaultBorder,
});

interface GetDefaultLayout {
  pageId: PageIdType;
  id: LayoutIdType;
  blockId: BlockIdType;
  layoutMargin?: number;
}
export const getDefaultLayout = ({ pageId, id, blockId, layoutMargin }: GetDefaultLayout): ILayout => ({
  id: id,
  parentId: pageId,
  type: LAYOUT.CONTENTS,
  blockIds: [blockId],
  style: getDefaultLayoutStyle(layoutMargin),
});
export const getDefaultEmptyLayout = (pageId: PageIdType, id: LayoutIdType): ILayout => {
  return {
    id: id,
    parentId: pageId,
    type: LAYOUT.EMPTY,
    blockIds: [],
    style: {
      backgroundType: LAYOUT_OPTION.IMAGE,
      backgroundColor: transparentize(COLORS.white),
      desktopImageOffset: null,
      mobileImageOffset: null,
      ratioType: Ratio.FULL,
      width: EDITOR_BLOCK_WIDTH,
      height: 0,
      margin: 0,
      overlayColor: DEFAULT_OVERLAY_COLOR,
      overlayRatio: null,
      backgroundBrandColorToggle: OFF,
      borderRadius: DefaultBorder,
    },
  };
};

interface GetDefaultBlock {
  layoutId: LayoutIdType;
  id: BlockIdType;
  groupId: GroupIdType;
  blockGap?: number;
  contentsGroupGap?: number;
  globalBackgroundColor?: string;
}
export const getDefaultBlock = ({
  layoutId,
  id,
  groupId,
  blockGap,
  contentsGroupGap,
  globalBackgroundColor,
}: GetDefaultBlock): IBlock => {
  const getPadding = (): PaddingStyle => {
    const defaultPadding = BlockPaddingMap[EnumPadding.MEDIUM as PaddingType];
    if (blockGap === undefined || blockGap === null) return defaultPadding;
    return { ...defaultPadding, type: EnumPadding.CUSTOM, paddingTop: blockGap, paddingBottom: blockGap };
  };

  return {
    id: id,
    type: Block.DEPTH1,
    parentId: layoutId,
    pcSortOrder: '0',
    mobileSortOrder: '0',
    style: {
      backgroundColor: globalBackgroundColor || transparentize(COLORS.white),
      padding: getPadding(),

      backgroundBrandColorToggle: OFF,
      gap: contentsGroupGap ?? DEFAULT_CONTENTS_GROUP_GAP,
    },
    groupIds: [groupId],
  };
};
export const defaultGroupImageStyle: IGroupImageStyle = {
  imageOffset: null,
  overlayColor: DEFAULT_OVERLAY_COLOR,
  overlayRatio: null,
  shadowStatus: 'OFF',
  borderRadius: DefaultBorder,
  padding: GroupPaddingMap.EMPTY,
};
export const defaultGroupStyle: IGroupStyle = {
  backgroundType: LAYOUT_OPTION.IMAGE,
  backgroundColor: transparentize(COLORS.white),
  desktopImageStyle: defaultGroupImageStyle,
  mobileImageStyle: defaultGroupImageStyle,
  mobileImageStyleStatus: 'OFF',
  backgroundBrandColorToggle: OFF,
};

export const getDefaultGroup = (
  blockId: BlockIdType,
  id: GroupIdType,
  contentsId: ContentsIdType,
  option: { columnNumber: number; pcSortOrder: number; mobileSortOrder: number } = {
    columnNumber: 0,
    pcSortOrder: 0,
    mobileSortOrder: 0,
  }
): IGroup => {
  return {
    id: id,
    parentId: blockId,
    contentsIds: [contentsId],
    style: defaultGroupStyle,
    hyperLink: DefaultLinkStyle,
    ...option,
  };
};

export const getDefaultContents = (groupId: GroupIdType, id: ContentsIdType): IContents<any> => {
  return {
    id: id,
    parentId: groupId,
    type: CONTENTS.EMPTY,
    value: undefined,
    style: undefined,
  };
};

export interface IEditor {
  page: {
    allIds: PageIdType[];
    byIds: {
      [id: PageIdType]: IPage;
    };
  };
  layout: {
    allIds: LayoutIdType[];
    byIds: {
      [id: LayoutIdType]: ILayout;
    };
  };
  block: {
    allIds: BlockIdType[];
    byIds: {
      [id: BlockIdType]: IBlock;
    };
  };
  group: {
    allIds: GroupIdType[];
    byIds: {
      [id: GroupIdType]: IGroup;
    };
  };
  contents: {
    allIds: ContentsIdType[];
    byIds: {
      [id: ContentsIdType]: IContents<any>; // FIXME any 타입 수정
    };
  };
}

export const DefaultEditor: IEditor = {
  page: {
    allIds: [ids.page_0_id],
    byIds: {
      [ids.page_0_id]: getDefaultPage(ids.page_0_id, ids.layout_0_id, ids.layout_1_id),
    },
  },
  layout: {
    allIds: [ids.layout_0_id, ids.layout_1_id],
    byIds: {
      [ids.layout_0_id]: getDefaultLayout({ pageId: ids.page_0_id, id: ids.layout_0_id, blockId: ids.block_0_id }),
      [ids.layout_1_id]: getDefaultEmptyLayout(ids.page_0_id, ids.layout_1_id),
    },
  },
  block: {
    allIds: [ids.block_0_id],
    byIds: {
      [ids.block_0_id]: getDefaultBlock({ layoutId: ids.layout_0_id, id: ids.block_0_id, groupId: ids.group_0_id }),
    },
  },
  group: {
    allIds: [ids.group_0_id],
    byIds: {
      [ids.group_0_id]: getDefaultGroup(ids.block_0_id, ids.group_0_id, ids.contents_0_id),
    },
  },
  contents: {
    allIds: [ids.contents_0_id],
    byIds: {
      [ids.contents_0_id]: getDefaultContents(ids.group_0_id, ids.contents_0_id),
    },
  },
};
