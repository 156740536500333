import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import classnames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { isOnOff, OFF, ON } from '../../../constants/common';
import { Align, convertAlignToFlex } from '../../../interface/common/Align';
import { LINK_TYPE } from '../../../interface/common/Link';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { HasOption } from '../../../interface/common/hasOption';
import IViewContents from '../../../interface/contents/IViewContents';
import { IImage } from '../../../interface/contents/image/IImage';
import IImageMobileStyle from '../../../interface/contents/image/IImageMobileStyle';
import IImageStyle from '../../../interface/contents/image/IImageStyle';
import { URL_TYPE } from '../../../interface/header/IMenu';
import styles from './ViewImage.module.scss';
import ViewImageHover from './ViewImageHover';
import { DefaultBorder } from '../../../interface/common/Border';

const cx = classnames.bind(styles);

interface IViewImageProps {
  viewMode?: ViewModeType;
  viewImage: IViewContents<IImage>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewImage: FC<IViewImageProps & HasOption> = ({ viewMode, viewImage, isPreview, isEdit, options }) => {
  const { companyName, getPathName, findPageTitle, pageList } = options?.pageMetadataHooks ?? {
    companyName: undefined,
    getPathName: undefined,
    findPageTitle: undefined,
    pageList: undefined,
  };

  const ImageStyle = (): IImageStyle | IImageMobileStyle => {
    if (viewMode === VIEW_MODE.MOBILE && viewImage?.mobileStyleStatus === ON) return viewImage.mobileStyle!;
    return viewImage.style;
  };

  const {
    align,
    roundStatusType,
    monochromeStatusType,
    shadowStatusType,
    size,
    borderRadius: _borderRadius,
  } = ImageStyle();

  const borderRadius = _borderRadius ?? DefaultBorder;
  const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = borderRadius;

  const pcViewImageUrl = getImageURL({
    fileUid: viewImage?.value?.desktopImage?.fileUid,
    fileUrl: viewImage?.value?.desktopImage?.fileUrl,
  });

  const mobileViewImageUrl = getImageURL({
    fileUid: viewImage?.value?.mobileImage?.fileUid,
    fileUrl: viewImage?.value?.mobileImage?.fileUrl,
  });

  const link = viewImage?.link;
  const isExternal = link?.linkType === LINK_TYPE.URL || link?.pageUrl?.urlType === URL_TYPE.EXTERNAL;
  const hasLink = isOnOff(link?.linkStatusType ?? OFF);

  const imageSrc = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE && mobileViewImageUrl) return mobileViewImageUrl;
    return pcViewImageUrl ?? '';
  }, [pcViewImageUrl, mobileViewImageUrl, viewMode]);

  const imageAlt = useMemo(() => {
    if (!companyName || !getPathName || !findPageTitle) return '';
    if (isExternal) return `${viewImage?.link?.url}와(과) 연결되었습니다.`;
    if (viewImage?.link?.url) return `${companyName}의 ${findPageTitle(viewImage?.link?.url)}와(과) 연결되었습니다.`;
    return `${companyName}의 ${findPageTitle(getPathName())}을(를) 소개하는 이미지입니다.`;
  }, [companyName, getPathName, findPageTitle, viewImage?.link?.url, isExternal]);

  if (!viewImage || !viewImage?.value) return null;

  if (!pcViewImageUrl && !mobileViewImageUrl) return null;

  const getBorderRadius = () => {
    if (isOnOff(roundStatusType)) return '100%';
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  };

  return (
    <div className={cx('wrapper')} style={{ justifyContent: convertAlignToFlex(Align[align]) }}>
      <div
        className={cx('image-size-area', { align, empty: !imageSrc, cursor: hasLink, shadow: shadowStatusType === ON })}
        style={{ width: `${size}%`, borderRadius: getBorderRadius() }}
      >
        <img
          className={cx('img', {
            mono: monochromeStatusType === ON,
            shadow: shadowStatusType === ON,
          })}
          src={imageSrc}
          alt={imageAlt}
        />
        <ViewImageHover
          viewMode={viewMode}
          viewImage={viewImage}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      </div>
    </div>
  );
};

export default ViewImage;
