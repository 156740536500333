import { IViewRecruitmentProps } from 'ui/component/contents/recruitment/useViewRecruitment';
import { IViewFontProps } from '../../hook/brand/useViewFont';
import { IViewBoardProps } from '../contents/board/useViewBoard';
import useBoardInjectedContext from './hooks/useBoardInjectedContext';
import useFontInjectedContext from './hooks/useFontInjectedContext';
import useRecruitInjectedContext from './hooks/useRecruitInjectedContext';

export interface IViewPageProps {
  viewRecruitmentProps: IViewRecruitmentProps;
  viewBoardProps: IViewBoardProps;
  viewFontProps: IViewFontProps;
}

const useViewPage = (viewPageProps: IViewPageProps | undefined) => {
  useRecruitInjectedContext(viewPageProps?.viewRecruitmentProps);
  useBoardInjectedContext(viewPageProps?.viewBoardProps);
  useFontInjectedContext(viewPageProps?.viewFontProps);
};

export default useViewPage;
