import classnames from 'classnames/bind';
import { FC, useContext, useMemo } from 'react';
import { Descendant } from 'slate';
import SlateEditor from '../../../../common/textEditor/SlateEditor';
import { isOnOff } from '../../../../constants/common';
import { FontInjectedContext } from '../../../../context/font/FontInjectedContext';
import useClickLinkedElement from '../../../../hook/link/useClickLinkedElement';
import { HasOption, PageList } from '../../../../interface/common/hasOption';
import { VIEW_MODE, ViewModeType } from '../../../../interface/common/ViewMode';
import { ISliderItem } from '../../../../interface/layout/slider/sliderItem/ISliderItem';
import {
  MOBILE_CONTAINER_IMAGE_HEIGHT,
  MOBILE_OVERFLOW_FULLSCREEN_IMAGE_HEIGHT,
  MOBILE_OVERFLOW_FULLSCREEN_IMAGE_WIDTH,
} from '../../../../interface/layout/slider/sliderStyle/ISliderItemImageSize';
import {
  ISliderStyle,
  SLIDER_TEXT_LOCATION_TYPE,
  SLIDER_VIEW,
} from '../../../../interface/layout/slider/sliderStyle/ISliderStyle';
import { percentageToHexAlpha } from '../../../../util/editor/color.utils';
import styles from './ViewSliderItem.module.scss';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  sliderItem: ISliderItem;
  sliderStyle: ISliderStyle;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewSliderItem: FC<IProps & HasOption> = ({
  viewMode,
  sliderItem,
  sliderStyle,
  isCapture,
  isPreview,
  isEdit,
  options,
}) => {
  const fontProps = useContext(FontInjectedContext);
  const pageLinkList = options?.pageLinkList as PageList;
  const languageType = options && options['languageType'];
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const { image, title, description, link } = sliderItem;
  const {
    viewType,
    sliderItemImageSize,
    borderRadius,
    textLocationType,
    overlayColor,
    overlayRatio,
    imageShowStatus,
    titleShowStatus,
    descriptionShowStatus,
  } = sliderStyle;

  const isTextLocationOverlayType = textLocationType === SLIDER_TEXT_LOCATION_TYPE.OVERLAY;
  const hasLink = isOnOff(link.linkStatusType);

  const imageStyle = useMemo(() => {
    const imageStyle = {
      width: `${sliderItemImageSize.width}px`,
      height: `${sliderItemImageSize.height}px`,
      borderRadius: `${borderRadius.leftTopRadius}px ${borderRadius.rightTopRadius}px ${borderRadius.rightDownRadius}px ${borderRadius.leftDownRadius}px`,
    };

    if (viewMode === VIEW_MODE.MOBILE && viewType === SLIDER_VIEW.CONTAINER) {
      imageStyle.width = '100%';
      imageStyle.height = `${MOBILE_CONTAINER_IMAGE_HEIGHT}px`;
    }

    if (viewMode === VIEW_MODE.MOBILE && viewType !== SLIDER_VIEW.CONTAINER) {
      imageStyle.width = `${Math.min(sliderItemImageSize.width, MOBILE_OVERFLOW_FULLSCREEN_IMAGE_WIDTH)}px`;
      imageStyle.height = `${Math.min(sliderItemImageSize.height, MOBILE_OVERFLOW_FULLSCREEN_IMAGE_HEIGHT)}px`;
    }

    return imageStyle;
  }, [viewMode, viewType, sliderItemImageSize, borderRadius]);

  const handleClick = () => {
    if (!hasLink) return;
    handleClickLinkedElement(link, isPreview, isEdit, languageType);
  };

  const parseSlateValue = (value: Descendant[] | string) => {
    return typeof value === 'string' ? JSON.parse(value) : value;
  };

  return (
    <div className={cx('card', viewMode, viewType, textLocationType)} style={{ minWidth: imageStyle.width }}>
      {isOnOff(imageShowStatus) && (
        <div className={cx('image-wrapper')}>
          <div className={cx('image', { 'has-link': hasLink })} style={{ ...imageStyle }}>
            {image &&
              (isCapture ? (
                <div
                  className={cx('capture')}
                  style={{
                    ...imageStyle,
                    backgroundImage: `url(${image.fileUrl})`,
                  }}
                />
              ) : (
                <img src={image.fileUrl} alt={image.fileName} style={{ ...imageStyle }} onClick={handleClick} />
              ))}
          </div>
        </div>
      )}
      {isOnOff(titleShowStatus) || isOnOff(descriptionShowStatus) ? (
        <div
          className={cx('text-container')}
          style={{
            backgroundColor: isTextLocationOverlayType
              ? `${overlayColor}${percentageToHexAlpha(overlayRatio)}`
              : 'transparent',
            borderRadius: `0 0 ${borderRadius.rightDownRadius - 3}px ${borderRadius.leftDownRadius - 3}px`,
          }}
        >
          {isOnOff(titleShowStatus) && (
            <div className={cx('text-editor-wrapper')}>
              <SlateEditor
                value={parseSlateValue(title)}
                onChange={() => {}}
                disabled={true}
                viewMode={viewMode}
                isPreview={isPreview}
                options={options}
                {...fontProps}
              />
            </div>
          )}
          {isOnOff(descriptionShowStatus) && (
            <div className={cx('text-editor-wrapper')}>
              <SlateEditor
                value={parseSlateValue(description)}
                onChange={() => {}}
                disabled={true}
                viewMode={viewMode}
                isPreview={isPreview}
                options={options}
                {...fontProps}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ViewSliderItem;
