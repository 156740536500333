import classNames from 'classnames/bind';
import Icon from '../../../../common/icon/Icon';
import { COLORS } from '../../../../constants/colors';
import { ON_OFF } from '../../../../constants/common';
import { IMap } from '../../../../interface/contents/map/IMap';
import styles from './Map.module.scss';
import useMap from './useMap';
import { CSSProperties, useRef } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../../interface/common/ViewMode';
const cx = classNames.bind(styles);

interface IProps {
  mapContent: IMap;
  viewMode?: ViewModeType;
  renderDependency?: unknown;
}

const Map = ({ mapContent, viewMode, renderDependency }: IProps) => {
  const { value, style } = mapContent;
  const { address, detailAddress, phoneNumber } = value;
  const {
    sizePercentage,
    markerName,
    guideTextStatus,
    alignType,
    borderRadius: { leftDownRadius, rightDownRadius, leftTopRadius, rightTopRadius },
  } = style;
  const { mapContainerRef, handleClickCopyIcon } = useMap({ cx, mapContent, renderDependency, viewMode });

  return (
    <div className={cx('map-wrap', alignType)}>
      <div data-id={'map'} style={{ width: `${sizePercentage}%` }}>
        {/** 카카오 맵이 들어갈 곳 */}
        <div
          ref={mapContainerRef}
          className={cx('map', viewMode === VIEW_MODE.MOBILE && 'mobile')}
          style={{
            borderRadius: `
            ${leftTopRadius}px 
            ${rightTopRadius}px 
            ${rightDownRadius}px 
            ${leftDownRadius}px`,
          }}
        />

        {guideTextStatus === ON_OFF.ON && (
          <div className={cx('description-wrap')}>
            <div className={cx('pin-name')}>{markerName}</div>
            <div className={cx('address-wrap')}>
              <span className={cx('basic-address')}>{address}</span>
              {address && detailAddress && <span className={cx('address-divider')}>,</span>}
              <span className={cx('detail-address')}>{detailAddress}</span>
              <button id={'copyButton'} onMouseDown={handleClickCopyIcon}>
                <Icon name={'copy_paste_line'} color={COLORS.gray600} className={cx('copy-icon')} />
              </button>
            </div>
            {phoneNumber && (
              <span className={cx('phone-number')}>
                <Icon name={'smartphone_line'} color={COLORS.gray600} />
                {phoneNumber}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
