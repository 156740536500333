import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { VIEW_MODE } from '../../../interface/common/ViewMode';
import { aToHex } from '../../../common/colorPicker/utils/convert';
import useFileSize from '../../../hook/useFileSize';
import { IViewLayoutBackgroundProps } from './ViewLayoutBackground';
import styles from './LayoutBackgroundImage.module.scss';
import classnames from 'classnames/bind';
import { BACKGROUND_MEDIA, useBackgroundFile } from '../../../hook/useBackgroundFile';

const cx = classnames.bind(styles);

const LayoutBackgroundImage: FC<IViewLayoutBackgroundProps> = ({ viewMode, viewLayout, isCapture = false }) => {
  const { overlayColor, overlayRatio } = viewLayout.style;

  const mediaWrapperRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { fileUrl: _fileUrl, fileType } = useBackgroundFile({
    desktopFileUid: viewLayout.style.desktopImageOffset?.fileUid ?? '',
    desktopFileUrl: viewLayout.style.desktopImageOffset?.fileUrl ?? '',
    mobileFileUid: viewLayout.style.mobileImageOffset?.fileUid ?? '',
    mobileFileUrl: viewLayout.style.mobileImageOffset?.fileUrl ?? '',
    viewMode,
  });
  const fileUrl = _fileUrl ?? '';
  const { fileSize: mediaFileSize } = useFileSize({ fileType, fileUrl });

  const isLargeMobileMedia = useMemo(
    () => viewMode === VIEW_MODE.MOBILE && mediaFileSize.height > 800,
    [viewMode, mediaFileSize.height]
  );

  const onLoadedVideoData = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    if (fileType === BACKGROUND_MEDIA.VIDEO && mediaWrapperRef.current && isCapture && isVideoLoaded) {
      const $video = mediaWrapperRef.current.querySelector('video') as HTMLVideoElement;
      const dataURL = getCapturedVideoDataURL($video, mediaWrapperRef.current);
      addCapturedVideoImageToMediaWrapper(dataURL, mediaWrapperRef.current);
    }

    function getCapturedVideoDataURL($video: HTMLVideoElement, $mediaWrapper: HTMLDivElement) {
      const $canvas = document.createElement('canvas');
      $canvas.width = $video.videoWidth;
      $canvas.height = $video.videoHeight;

      $canvas.getContext('2d')!.drawImage($video, 0, 0, $video.videoWidth, $video.videoHeight);
      $mediaWrapper.removeChild($video);
      $mediaWrapper.appendChild($canvas);
      const dataURL = $canvas.toDataURL('image/png');
      $mediaWrapper.removeChild($canvas);

      return dataURL;
    }

    function addCapturedVideoImageToMediaWrapper(dataURL: string, $mediaWrapper: HTMLDivElement) {
      const $capturedImage = document.createElement('div');
      $capturedImage.className = cx('media', 'capture');
      $capturedImage.style.backgroundImage = `url('${dataURL}')`;
      $capturedImage.style.width = '100%';
      $capturedImage.style.height = '100%';
      $capturedImage.style.position = 'absolute';
      $capturedImage.style.top = '0';
      $mediaWrapper.appendChild($capturedImage);
    }
  }, [isVideoLoaded]);

  const getMedia = () => {
    switch (fileType) {
      case BACKGROUND_MEDIA.IMAGE:
        return isCapture ? (
          <div
            className={cx('media', 'capture')}
            style={{
              backgroundImage: `url(${fileUrl})`,
            }}
          />
        ) : (
          <img className={cx('media')} src={fileUrl} alt={''} />
        );
      case BACKGROUND_MEDIA.VIDEO:
        return isCapture ? (
          <video className={cx('media')} crossOrigin={'anonymous'} onLoadedData={onLoadedVideoData} autoPlay>
            <source src={fileUrl} />
          </video>
        ) : (
          <video className={cx('media')} src={fileUrl} autoPlay loop muted playsInline />
        );
      default:
        return null;
    }
  };

  if (!fileUrl) return null;

  return (
    <div
      className={cx('wrapper', viewMode)}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div className={cx('imageSizeWrap')}>
        <div
          className={cx('media-wrapper', viewMode, { correctLargeMobile: isLargeMobileMedia })}
          ref={mediaWrapperRef}
        >
          {getMedia()}
        </div>
        <div
          className={cx('overlay')}
          style={{ backgroundColor: `${overlayColor}${aToHex((overlayRatio ?? 0) / 100)}` }}
        />
      </div>
    </div>
  );
};

export default LayoutBackgroundImage;
