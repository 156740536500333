import { FC, MouseEvent } from 'react';
import styles from './ViewSliderNavigator.module.scss';
import classnames from 'classnames/bind';
import { isOnOff } from 'ui/constants/common';
import { COLORS } from 'ui/constants/colors';
import { percentageToHexAlpha } from 'ui/util/editor/color.utils';
import { ISliderStyle } from '../../../../interface/layout/slider/sliderStyle/ISliderStyle';
import Icon from '../../../../common/icon/Icon';

const cx = classnames.bind(styles);

interface IProps {
  sliderStyle: ISliderStyle;
  canMovePrev: boolean;
  onClickPrev: () => void;
  canMoveNext: boolean;
  onClickNext: () => void;
}

const ViewSliderNavigator: FC<IProps> = ({ sliderStyle, canMovePrev, onClickPrev, canMoveNext, onClickNext }) => {
  const viewType = sliderStyle.viewType;
  const { darkModeStatus, verticalAlignType } = sliderStyle.navigationStyle;
  const isDarkMode = isOnOff(darkModeStatus);

  const getNavigatorColor = (isAbled: boolean) => {
    if (isDarkMode) {
      return isAbled ? COLORS.white : `${COLORS.white}${percentageToHexAlpha(30)}`;
    } else {
      return isAbled ? COLORS.gray700 : COLORS.gray400;
    }
  };

  const handleClickPrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickPrev();
  };

  const handleClickNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickNext();
  };

  return (
    <div className={cx('container', viewType)}>
      <div className={cx('arrows', verticalAlignType)}>
        <button
          className={cx('arrow', { darkMode: isDarkMode })}
          type={'button'}
          disabled={!canMovePrev}
          onMouseDown={handleClickPrev}
        >
          <Icon name={'arrow_back_line'} size={24} color={getNavigatorColor(canMovePrev)} />
        </button>
        <button
          className={cx('arrow', { darkMode: isDarkMode })}
          type={'button'}
          disabled={!canMoveNext}
          onMouseDown={handleClickNext}
        >
          <Icon name={'arrow_forward_line'} size={24} color={getNavigatorColor(canMoveNext)} />
        </button>
      </div>
    </div>
  );
};

export default ViewSliderNavigator;
