import { useQueryGetBrandFontListV1 } from '@queries/brand/font';
import { useLayoutEffect } from 'react';
import { convertBrandFontList, loadFonts } from 'ui/util/font.utils';

export const useApplyBrandFont = () => {
  const { data } = useQueryGetBrandFontListV1();
  const brandFontList = convertBrandFontList(data?.brandFontFileList ?? []);

  useLayoutEffect(() => {
    loadFonts(brandFontList);
  }, [brandFontList]);

  return { brandFontList };
};
