import { OnOffType } from '../../../../constants/common';

export const VERTICAL_ALIGN = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
} as const;

export type VerticalAlignType = (typeof VERTICAL_ALIGN)[keyof typeof VERTICAL_ALIGN];

export interface INavigationStyle {
  activeStatus: OnOffType; // 활성화
  darkModeStatus: OnOffType; // 다크모드
  verticalAlignType: VerticalAlignType; // 세로정렬 위치
}
