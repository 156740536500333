import { DEFAULT_OVERLAY_COLOR } from 'ats-editor/src/constant/commonDefaultEditorConfig';
import classnames from 'classnames/bind';
import { FC, MouseEventHandler, useContext, useState } from 'react';
import SlateEditor from '../../../common/textEditor/SlateEditor';
import { isOnOff, OFF } from '../../../constants/common';
import { FontInjectedContext } from '../../../context/font/FontInjectedContext';
import useClickLinkedElement from '../../../hook/link/useClickLinkedElement';
import { HasOption, PageList } from '../../../interface/common/hasOption';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { IImage } from '../../../interface/contents/image/IImage';
import { IMAGE_HOVER_ACTIVE_TYPE, IMAGE_TEXT_LOCATION_TYPE } from '../../../interface/contents/image/IImageHover';
import IViewContents from '../../../interface/contents/IViewContents';
import { clarify } from '../../../util/color.utils';
import { checkValidLink } from '../../../util/link.utils';
import styles from './ViewImageHover.module.scss';
const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  viewImage: IViewContents<IImage>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewImageHover: FC<IProps & HasOption> = ({ viewMode, viewImage, isPreview, isEdit, options }) => {
  const fontProps = useContext(FontInjectedContext);
  const pageLinkList = options?.pageLinkList as PageList;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const languageType = options && options['languageType'];

  const { link, hover } = viewImage;
  const text = hover?.text;
  const isHoverToggleEnabled = isOnOff(hover?.hoverToggle ?? OFF);

  const textLocation = hover?.textLocationType ?? IMAGE_TEXT_LOCATION_TYPE.CENTER;
  const overlayColor = hover?.overlayColor ?? DEFAULT_OVERLAY_COLOR;
  const overlayRatio = hover?.overlayRatio ?? 0;

  const hasLink = isOnOff(link?.linkStatusType ?? OFF);
  const isHoverActiveAlways = viewImage?.hover?.activeType === IMAGE_HOVER_ACTIVE_TYPE.ALWAYS;

  const [isMobileVisible, setIsMobileVisible] = useState(isHoverActiveAlways);

  const editorTextValue = typeof text === 'string' ? JSON.parse(text) : text;
  const isEditorTextValueValid = !!editorTextValue;

  const handleClickLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!hasLink || !checkValidLink(link)) return;
    // 링크 모바일 이미지 등록 여부와 관계없이 PC와 모바일 이미지가 PC에서 설정한 링크로 동일하게 이동된다
    handleClickLinkedElement(link, isPreview, isEdit, languageType);
  };

  const handleClick: MouseEventHandler<Element> = (e) => {
    if (viewMode === VIEW_MODE.MOBILE) {
      if (!isHoverToggleEnabled) {
        handleClickLink(e);
        return;
      }

      if (isHoverActiveAlways) {
        handleClickLink(e);
        return;
      }

      setIsMobileVisible(!isMobileVisible);
      if (isMobileVisible) {
        handleClickLink(e);
        return;
      }

      e.stopPropagation(); // 모바일 클릭 토글에서도 이벤트 버블링 전파를 막는다
    } else {
      handleClickLink(e);
    }
  };

  return (
    <div
      className={cx(
        'container',
        { 'toggle-enabled': isHoverToggleEnabled },
        { 'active-always': isHoverActiveAlways },
        viewMode,
        { 'mobile-toggle-active': isMobileVisible }
      )}
      onClick={handleClick}
    >
      <div
        className={cx('overlay')}
        style={{
          backgroundColor: clarify(overlayColor, overlayRatio),
        }}
      />
      <div className={cx('editor-wrapper', IMAGE_TEXT_LOCATION_TYPE[textLocation])}>
        {isEditorTextValueValid && (
          <SlateEditor
            value={editorTextValue}
            onChange={() => {}}
            disabled={true}
            viewMode={viewMode}
            isPreview={isPreview}
            options={options}
            fullWidth
            {...fontProps}
          />
        )}
      </div>
    </div>
  );
};

export default ViewImageHover;
