import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { OFF, ON } from '../../../constants/common';
import IViewContents from '../../../interface/contents/IViewContents';
import { IVideo } from '../../../interface/contents/video/IVideo';

interface IProps {
  viewVideo: IViewContents<IVideo>;
}

const useViewVideoEventHandler = ({ viewVideo }: IProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [isInStartDelay, setIsInStartDelay] = useState(true);
  const [showThumbnailImage, setShowThumbnailImage] = useState(true);
  const [player, setPlayer] = useState<any>(null);
  const { style, value } = viewVideo;
  const { autoPlayStatus, repeatPlayStatus } = style;
  const { thumbnailImage } = value;

  const [isLoop, setIsLoop] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const stopVideoProcess = useCallback(() => {
    player?.stopVideo();
    setShowThumbnailImage(true);
    if (autoPlayStatus === ON) {
      setIsMuted(true);
      player?.playVideo();
    }
  }, [player, autoPlayStatus]);

  useEffect(() => {
    const videoQualityArray = player?.getAvailableQualityLevels() ?? ['auto'];
    player?.setPlaybackQuality(videoQualityArray[0]);
  }, [player]);

  useEffect(() => {
    setIsLoop(repeatPlayStatus === ON);
    stopVideoProcess();
  }, [player, repeatPlayStatus, stopVideoProcess]);

  useEffect(() => {
    if (autoPlayStatus === ON) {
      setIsLoop(true);
      player?.playVideo();
      setShowThumbnailImage(false);
    } else {
      stopVideoProcess();
    }
  }, [player, autoPlayStatus, stopVideoProcess]);

  useEffect(() => {
    if (!thumbnailImage?.fileName) {
      setShowThumbnailImage(false);
    } else {
      stopVideoProcess();
    }
  }, [player, thumbnailImage?.fileName, stopVideoProcess]);

  const handleStartDelay = () => {
    setIsInStartDelay(true);
    setTimeout(() => {
      setIsInStartDelay(false);
    }, 1000);
  };

  const onReady = () => {
    setPlayer(playerRef.current?.getInternalPlayer());
    handleStartDelay();

    if (autoPlayStatus === OFF) return;

    setShowThumbnailImage(false);
    setTimeout(() => {
      setIsMuted(true);
      player?.playVideo();
    }, 100);
  };

  const onPlay = () => {
    if (isInStartDelay) return;
  };

  const onPause = () => {
    player?.pauseVideo();
  };

  const onEnd = () => {
    if (repeatPlayStatus === ON) {
      handleStartDelay();
      setShowThumbnailImage(false);
      player?.playVideo();
    } else {
      setShowThumbnailImage(true);
    }
  };

  const handleClickPlayer = () => {
    setShowThumbnailImage(false);
    player?.playVideo();
  };

  return { playerRef, onReady, onPlay, onPause, onEnd, showThumbnailImage, handleClickPlayer, isLoop, isMuted };
};

export default useViewVideoEventHandler;
