import { FC } from 'react';

interface IProps {
  width?: string | number;
  height?: string | number;
}
const Space: FC<IProps> = ({ width, height }) => {
  return (
    <div
      style={{
        flex: 'none',
        width,
        height,
      }}
    />
  );
};

export default Space;
