'use client';

import classNames from 'classnames/bind';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import Pagination from 'ui/common/pagination/Pagination';
import RecruitList from 'ui/component/recruiter/recruitList/RecruitList';
import { IRecruit } from 'ui/interface/recruit/IRecruitList';
import PaginationVO from 'ui/model/vo/PaginationVO';
import { DEFAULT_PAGE } from '../../../../constants/common';
import RecruitListInjectedContext from '../../../../context/recruiter/RecruitListInjectedContext';
import RecruitSettingContext from '../../../../context/recruiter/RecruitSettingContext';
import RecruitSettingInjectedContext from '../../../../context/recruiter/RecruitSettingInjectedContext';
import { VIEW_MODE, ViewModeType } from '../../../../interface/common/ViewMode';
import IViewContents from '../../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../../interface/contents/recruitment/IRecruitment';
import { RECRUITMENT_VIEW } from '../../../../interface/contents/recruitment/IRecruitmentStyle';
import { IPostListV1Response } from '../../../../interface/position/getList';
import RecruitKanban from '../../../recruiter/recruitList/RecruitKanban';
import style from './RecruitViewList.module.scss';
import useMultiLanguage from '../../../../hook/useMultiLanguage';

const cx = classNames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const RecruitViewList: FC<IProps> = ({ viewMode, viewRecruitment, isPreview, isEdit }) => {
  const sideFilterPcLimitViewMode: ViewModeType | undefined =
    viewRecruitment.style.viewType === RECRUITMENT_VIEW.SIDE_FILTER && viewMode === VIEW_MODE.PC
      ? VIEW_MODE.TABLET
      : viewMode;
  const { tag, keyword, careerType, workingArea, jobGroup, submissionStatus, openStatus } =
    useContext(RecruitSettingContext);
  const { queryGetSettingData } = useContext(RecruitSettingInjectedContext);
  const { sortType } = queryGetSettingData;

  const {
    postList: _postList,
    postListPreview: _postListPreview,
    mutatePostListData: _mutatePostListData,
    mutatePostListPreviewData: _mutatePostListPreviewData,
    setMutatePostListData: _setMutatePostListData,
    setMutatePostListPreviewData: _setMutatePostListPreviewData,
  } = useContext(RecruitListInjectedContext);

  const isPreviewOrEdit = isPreview || isEdit;
  const postListFunc = isPreviewOrEdit ? _postListPreview : _postList;
  const postListData = isPreviewOrEdit ? _mutatePostListPreviewData : _mutatePostListData;
  const setPostListData = isPreviewOrEdit ? _setMutatePostListPreviewData : _setMutatePostListData;

  const { size } = viewRecruitment.value;
  const { viewType, badgeColor, badgeTextColor } = viewRecruitment.style;

  const [list, setList] = useState<IRecruit[]>([]);

  const fetchList = useCallback(
    (page: number) => {
      postListFunc?.(
        {
          pageableRq: {
            page,
            size,
            sort: [sortType],
          },
          filter: {
            keyword,
            tagSnList: tag,
            jobGroupSnList: jobGroup,
            careerTypeList: careerType,
            regionSnList: workingArea,
            submissionStatusList: submissionStatus,
            openStatusList: isPreview || isEdit ? ['OPEN'] : [],
          },
        },
        {
          onSuccess: (data: IPostListV1Response) => {
            setList(data.list);
          },
        }
      );
    },
    [
      sortType,
      isPreview,
      isEdit,
      tag,
      keyword,
      jobGroup,
      careerType,
      workingArea,
      size,
      submissionStatus,
      setList,
      postListFunc,
    ]
  );

  useEffect(() => {
    setPostListData({
      ...postListData,
      pagination: {
        ...postListData.pagination,
        page: DEFAULT_PAGE,
      },
    });
  }, [
    postListData.pagination.size,
    tag,
    keyword,
    careerType,
    workingArea,
    jobGroup,
    submissionStatus,
    openStatus,
    sortType,
    isPreview,
    setPostListData,
  ]);

  useEffect(() => {
    fetchList(postListData.pagination.page);
  }, [fetchList, postListData.pagination.page]);

  return (
    <>
      {(viewType ?? RECRUITMENT_VIEW.LIST) === RECRUITMENT_VIEW.KANBAN ? (
        <RecruitKanban
          viewMode={viewMode}
          recruitList={list}
          badgeColor={badgeColor}
          JDUrl={'jobs'}
          readOnly={isPreview}
        />
      ) : (
        <RecruitList
          viewMode={sideFilterPcLimitViewMode}
          recruitList={list}
          badgeColor={badgeColor}
          badgeTextColor={badgeTextColor}
          JDUrl={'jobs'}
          readOnly={isPreview}
        />
      )}
      {list.length > 0 && (
        <div className={cx('pagination')}>
          <Pagination
            showOnlyPagination
            page={
              new PaginationVO({
                pageSize: postListData.pagination.size,
                ...postListData.pagination,
              })
            }
            pageHandler={({ page }) => {
              setPostListData({
                ...postListData,
                pagination: {
                  ...postListData.pagination,
                  page,
                },
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default RecruitViewList;
