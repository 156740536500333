import { FC, PropsWithChildren } from 'react';
import { ViewModeType } from '../../interface/common/ViewMode';
import IViewLayout from '../../interface/layout/IViewLayout';
import { DEFAULT_PAGE_STYLE } from 'ats-editor/src/constant/DefaultEditor';
import Space from 'ui/component/common/Space';

interface IProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isLast: boolean;
  isPreview?: boolean;
  previewTabType?: ViewModeType;
}
const ViewLayoutWrapper: FC<PropsWithChildren<IProps>> = (props) => {
  const { children, viewLayout, isLast } = props;
  const margin = viewLayout?.style?.margin;

  const getLayoutMargin = () => {
    if (isLast) return 0;
    return margin ?? DEFAULT_PAGE_STYLE.layoutMarginBottom;
  };

  return (
    <>
      {children}
      {<Space height={getLayoutMargin()} />}
    </>
  );
};

export default ViewLayoutWrapper;
