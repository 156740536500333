import classNames from 'classnames/bind';
import Link from 'next/link';
import { FC } from 'react';
import Tooltip from '../../../common/tooltip';
import { COLORS } from '../../../constants/colors';
import useWarningToast from '../../../context/toast/useWarningToast';
import { Nullable } from '../../../interface/common/common';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { IRecruit } from '../../../interface/recruit/IRecruitList';
import Divider from '../../common/Divider';
import NoResult from '../../common/NoResult';
import style from './RecruitKanban.module.scss';
import useGetRecruitItemText from './useGetRecruitItemText';
import useRecruitItemUrl from './useRecruitItemUrl';

const cx = classNames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  recruitList: IRecruit[];
  badgeColor: Nullable<string>;
  JDUrl?: string;
  readOnly?: boolean;
}

const RecruitKanban: FC<IProps> = ({ viewMode = 'PC', recruitList = [], badgeColor, JDUrl = '', readOnly = false }) => {
  const { setMobilePreviewDisabledWarningToast } = useWarningToast();
  const { getRecruitUrl } = useRecruitItemUrl({ readOnly });
  const { checkIsAlways, getRecruitFilterTextList, getRecruitTagText, getDateText, getDdayText } =
    useGetRecruitItemText();

  // 검색에 충족하는 공고가 없음
  if (recruitList.length === 0) {
    return <NoResult title={'진행 중인 공고가 없어요.'} />;
  }

  return (
    <ul className={cx('recruit-kanban')}>
      {recruitList.map((recruit) => {
        const recruitUrl = getRecruitUrl(recruit.positionSn, JDUrl);
        const { title, positionSn, tagList, startDateTime, endDateTime } = recruit;
        const filterTextList = getRecruitFilterTextList(recruit);
        const tagText = getRecruitTagText(tagList);
        const dateText = getDateText(startDateTime, endDateTime);
        const ddayText = getDdayText(recruit);

        const isClosed = new Date(endDateTime) < new Date();
        const isAlways = checkIsAlways(recruit);
        const ddayColor = !isAlways && isClosed ? COLORS.gray400 : badgeColor ?? COLORS.teal600;

        return (
          <li className={cx('recruit-item-wrapper')} key={positionSn}>
            <Link
              className={cx('recruit-item')}
              href={recruitUrl}
              onClick={(e) => {
                if (!JDUrl) {
                  e.preventDefault();
                }
                if (readOnly && viewMode === VIEW_MODE.MOBILE) {
                  e.preventDefault();
                  setMobilePreviewDisabledWarningToast();
                }
              }}
              target={'_blank'}
            >
              <div className={cx('item-header')}>
                <ul className={cx('filter-list')}>
                  {filterTextList.map((filter, i) => (
                    <li className={cx('filter-item')} key={filter}>
                      {i > 0 && <Divider direction={'column'} length={12} margin={10} />}
                      <span>{filter}</span>
                    </li>
                  ))}
                </ul>
                <p className={cx('recruit-title')}>{title}</p>
              </div>
              <div className={cx('item-footer')}>
                <div className={cx('tag-and-date')}>
                  <p className={cx('tag')}>
                    {tagText.summaryText}
                    {tagText.fullText && (
                      <Tooltip className={cx('tag-tooltip')} tailPosition={'bottom-left'}>
                        <Tooltip.Text>
                          <p className={cx('tag-tooltip-text')}>{tagText.fullText}</p>
                        </Tooltip.Text>
                      </Tooltip>
                    )}
                  </p>
                  <p className={cx('date')}>{dateText}</p>
                </div>
                <p
                  className={cx('dday')}
                  style={{
                    color: ddayColor,
                  }}
                >
                  {ddayText}
                </p>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default RecruitKanban;
