import { COLUMN, COLUMN_NUMBER, ColumnType } from '../ISlider';
import { Nullable } from '../../../common/common';

export interface ISliderItemImageSize {
  ratio: Nullable<number>; // 이미지 비율
  width: number; // 이미지 가로
  height: number; // 이미지 세로
}

export const CONTAINER_SLIDER_FULL_WIDTH = 1216;
const IMAGE_DEFAULT_HEIGHT = 300;
export const SLIDER_GAP = 20;
export const IMAGE_MIN_WIDTH = 30;
export const IMAGE_MAX_WIDTH = CONTAINER_SLIDER_FULL_WIDTH;
export const IMAGE_MIN_HEIGHT = 10;
export const IMAGE_MAX_HEIGHT = 960;
export const MOBILE_CONTAINER_IMAGE_HEIGHT = 300;
export const MOBILE_OVERFLOW_FULLSCREEN_IMAGE_WIDTH = 210;
export const MOBILE_OVERFLOW_FULLSCREEN_IMAGE_HEIGHT = 200;
export const MOBILE_OVERFLOW_FULLSCREEN_SLIDER_GAP = 16;

const getSliderItemWidth = (columnType: ColumnType) => {
  const columnNumber = COLUMN_NUMBER[columnType];
  return (CONTAINER_SLIDER_FULL_WIDTH - SLIDER_GAP * (columnNumber - 1)) / columnNumber;
};

export const DEFAULT_SLIDER_ITEM_IMAGE_SIZE: Record<ColumnType, { width: number; height: number }> = {
  COLUMN1: { width: getSliderItemWidth(COLUMN.COLUMN1), height: IMAGE_DEFAULT_HEIGHT },
  COLUMN2: { width: getSliderItemWidth(COLUMN.COLUMN2), height: IMAGE_DEFAULT_HEIGHT },
  COLUMN3: { width: getSliderItemWidth(COLUMN.COLUMN3), height: IMAGE_DEFAULT_HEIGHT },
  COLUMN4: { width: getSliderItemWidth(COLUMN.COLUMN4), height: IMAGE_DEFAULT_HEIGHT },
};

export const calculateSliderItemImageRatio = (width: number, height: number) => {
  return Math.round((width / height) * 1000) / 1000;
};
