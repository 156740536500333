import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import { FC } from 'react';
import ViewBlock from '../../block/ViewBlock';
import IPageStyle from '../../../interface/page/IPageStyle';
import { HasOption } from '../../../interface/common/hasOption';
import ViewLayoutBackground from '../ViewLayoutBackground/ViewLayoutBackground';
import styles from '../ViewLayout.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

interface IViewLayoutDefaultProps {
  pageStyle: IPageStyle;
  previewTabType?: ViewModeType;
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewLayoutDefault: FC<IViewLayoutDefaultProps & HasOption> = (props) => {
  const { viewLayout, viewMode, pageStyle, isCapture, isPreview, isEdit, previewTabType, options } = props;
  const blockEls = viewLayout.blocks.map((viewBlock) => {
    return (
      <ViewBlock
        key={viewBlock.id}
        viewMode={viewMode}
        pageStyle={pageStyle}
        viewBlock={viewBlock}
        isCapture={isCapture}
        isPreview={isPreview}
        isEdit={isEdit}
        previewTabType={previewTabType}
        options={options}
      />
    );
  });

  return (
    <div className={cx('container')}>
      <ViewLayoutBackground viewLayout={viewLayout} viewMode={viewMode} isCapture={isCapture} />
      {blockEls}
    </div>
  );
};

export default ViewLayoutDefault;
