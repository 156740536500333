import { FC, useEffect, useState } from 'react';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import IViewLayout from '../../../../interface/layout/IViewLayout';
import style from './ViewBannerSlide.module.scss';
import classnames from 'classnames/bind';
import ViewBannerImage from '../ViewBannerImage';
import { HasOption, PageList } from '../../../../interface/common/hasOption';
import useClickLinkedElement from '../../../../hook/link/useClickLinkedElement';
import BannerSlideNavigator from '../BannerSlideNavigator';
import { isOnOff, OFF } from '../../../../constants/common';
import { LinkStyle } from '../../../../interface/common/Link';
const cx = classnames.bind(style);

const SLIDE_VIRTUALIZATION_THRESHOLD = 2;
const SLIDE_AUTO_SWITCH_INTERVAL = 3 * 1000;

interface IProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}
const ViewBannerSlide: FC<IProps & HasOption> = ({ viewMode, viewLayout, isCapture, isPreview, isEdit, options }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoSwitch, setIsAutoSwitch] = useState(
    viewLayout.banner?.bannerSlide?.desktopStyle.imageAutoSwitchToggle ?? OFF
  );
  const bannerSlide = viewLayout.banner?.bannerSlide;
  const slideImageList = bannerSlide?.slideImageList.filter((item) => !!item) ?? [];

  const pageLinkList = options?.pageLinkList as PageList;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const languageType = options && options['languageType'];

  const maxCount = slideImageList.length;
  const getHasLink = (hyperLink?: LinkStyle) => isOnOff(hyperLink?.linkStatusType ?? OFF);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isOnOff(isAutoSwitch)) return;
      setCurrentIndex((prev) => (prev + 1) % maxCount);
    }, SLIDE_AUTO_SWITCH_INTERVAL);
    return () => clearInterval(timer);
  }, [isAutoSwitch, maxCount]);

  const clickBannerImageHandler = (hyperLink?: LinkStyle) => () => {
    handleClickLinkedElement(hyperLink, isPreview, isEdit, languageType);
  };

  return (
    <div className={cx('container')}>
      <div
        className={cx('slide-list')}
        style={{
          transform: `translateX(${-100 * currentIndex}%)`,
        }}
      >
        {slideImageList.map(({ hyperLink }, index) => (
          <div key={index} className={cx('slide-item')}>
            <ViewBannerImage
              viewMode={viewMode}
              viewLayout={viewLayout}
              options={options}
              isPreview={isPreview}
              isCapture={isCapture}
              slideIndex={index}
              onClick={getHasLink(hyperLink) ? clickBannerImageHandler(hyperLink) : undefined}
              shouldRender={Math.abs(currentIndex - index) <= SLIDE_VIRTUALIZATION_THRESHOLD}
            />
          </div>
        ))}
      </div>

      <BannerSlideNavigator
        count={currentIndex + 1}
        maxCount={maxCount}
        onClickPrev={() => setCurrentIndex((prev) => prev - 1)}
        isPrevDisabled={currentIndex === 0}
        onClickNext={() => setCurrentIndex((prev) => prev + 1)}
        isNextDisabled={currentIndex === maxCount - 1}
        renderAutoSwitch={isOnOff(viewLayout.banner?.bannerSlide?.desktopStyle.imageAutoSwitchToggle ?? OFF)}
        autoSwitchToggle={isAutoSwitch}
        onChangeAutoSwitchToggle={(value) => setIsAutoSwitch(value)}
      />
    </div>
  );
};

export default ViewBannerSlide;
