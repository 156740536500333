import _TooltipWrapper from './_TooltipWrapper';
import _TooltipText from './_TooltipText';
import _TooltipTitle from './_TooltipTitle';

export type TailPositions =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'right-top'
  | 'right'
  | 'right-bottom'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left-bottom'
  | 'left'
  | 'left-top';

const Tooltip = Object.assign(_TooltipWrapper, {
  Title: _TooltipTitle,
  Text: _TooltipText,
});

export default Tooltip;

/* EXAMPLE
 * <Tooltip tailPosition="left" className={cx('tooltipTest')}>
 *    <Tooltip.TooltipTitle>기타 경험</Tooltip.TooltipTitle>
 *    <Tooltip.TooltipText>
 *       <div style={{ marginTop: '2px' }}>
 *          툴팁 텍스트 샘플 툴팁 텍스트 샘플 툴팁 텍스트 샘플 툴팁 텍스트 샘플 툴팁
 *          텍스트 샘플 툴팁 텍스트 샘플
 *       </div>
 *    </Tooltip.TooltipText>
 * </Tooltip>
 */

/* NOTE
 * 툴팁의 위치 지정은 부모로부터 className을 직접 부여하여 부모가 위치지정할 수 있도록 한다.
 * 툴팁 컴포넌트는 단순히 툴팁의 모양이 어떤지 정의하는것에 지나지 않는다.
 * Hover시, Click시 등의 이벤트를 정의할 때는 부모에서 핸들링할 수 있게 한다.
 * 툴팁 컴포넌트 내부의 요소들은 요소사이 간격이 정의되어있지 않다.
 * 간격을 조정하고 싶다면 요소를 임의로 만들어 그 요소에 간격을 준다!
 * tailPosition은 꼬리 위치에 관한 것으로, 12개의 위치가 지정되어있다.
 * 해당 위치는 스토리북 혹은 _TooltipWrapper 안에 정의되어 있는 TailPositions를 참고할 것.
 */
