import { useContext, useEffect } from 'react';
import { baseFontList } from '../../../constants/font.constant';
import { FontInjectedContext } from '../../../context/font/FontInjectedContext';
import { IViewFontProps } from '../../../hook/brand/useViewFont';
import { convertBrandFontList } from '../../../util/font.utils';

const useFontInjectedContext = (viewFontProps?: IViewFontProps) => {
  const { setFontList } = useContext(FontInjectedContext);

  const { brandFontList: brandFontFileList } = viewFontProps || {};

  useEffect(() => {
    if (!brandFontFileList) return;
    const brandFontList = convertBrandFontList(brandFontFileList.brandFontFileList);
    setFontList([...brandFontList.reverse(), ...baseFontList]);
  }, [brandFontFileList, setFontList]);
};

export default useFontInjectedContext;
