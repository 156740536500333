import styles from './ViewSliderMobileContainerType.module.scss';
import classnames from 'classnames/bind';
import React, { FC, useRef, useState } from 'react';
import { ViewModeType } from '../../../../../interface/common/ViewMode';
import { HasOption } from '../../../../../interface/common/hasOption';
import ISlider from '../../../../../interface/layout/slider/ISlider';
import ViewSliderItem from '../../ViewSliderItem/ViewSliderItem';
import ViewSliderNavigatorProvider from '../../ViewSliderNavigator/ViewSliderNavigatorProvider';
import { DIRECTION } from '../../../../../interface/layout/slider/sliderStyle/IAutoSlide';
import { isOnOff } from '../../../../../constants/common';
import { Autoplay } from 'swiper/modules';
import { SLIDER_GAP } from '../../../../../interface/layout/slider/sliderStyle/ISliderItemImageSize';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper';

import 'swiper/css';
import { AUTO_SLIDER_DURATION_SECONDS } from '../../ViewSlider';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  slider: ISlider;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewSliderMobileContainerType: FC<IProps & HasOption> = ({
  viewMode,
  slider,
  isCapture,
  isPreview,
  isEdit,
  options,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const sliderItems = slider.items;
  const sliderStyle = slider.style;
  const isAutoPlayMode = isOnOff(sliderStyle.autoSlide.autoMode);
  const autoPlayDirection = sliderStyle.autoSlide.direction;
  const [atBeginning, setAtBeginning] = useState(false);
  const [atEnd, setAtEnd] = useState(false);

  const handleNavigatorClick = (direction: 'left' | 'right') => {
    if (!swiperRef.current) return;
    if (direction === 'left') {
      swiperRef.current.slidePrev();
    } else if (direction === 'right') {
      swiperRef.current.slideNext();
    }
  };

  const checkPosition = (swiper: SwiperCore) => {
    const current = swiper.translate;
    const min = swiper.minTranslate();
    const max = swiper.maxTranslate();

    setAtBeginning(current === min);
    setAtEnd(current === max);
  };

  return (
    <div className={cx('slider-container')}>
      <ViewSliderNavigatorProvider
        sliderStyle={sliderStyle}
        canMovePrev={!atBeginning}
        onClickPrev={() => handleNavigatorClick('left')}
        canMoveNext={!atEnd}
        onClickNext={() => handleNavigatorClick('right')}
      >
        <Swiper
          modules={[Autoplay]}
          grabCursor
          spaceBetween={SLIDER_GAP}
          slidesPerView={1}
          autoplay={
            isAutoPlayMode
              ? {
                  delay: AUTO_SLIDER_DURATION_SECONDS * 1000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  reverseDirection: autoPlayDirection === DIRECTION.LEFT,
                }
              : false
          }
          onTransitionEnd={(swiper) => {
            checkPosition(swiper);
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            checkPosition(swiper);
          }}
        >
          {sliderItems.map((sliderItem) => (
            <SwiperSlide style={{ width: sliderStyle.sliderItemImageSize.width }}>
              <ViewSliderItem
                key={sliderItem.id}
                sliderItem={sliderItem}
                sliderStyle={sliderStyle}
                viewMode={viewMode}
                isCapture={isCapture}
                isPreview={isPreview}
                isEdit={isEdit}
                options={options}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ViewSliderNavigatorProvider>
    </div>
  );
};

export default ViewSliderMobileContainerType;
