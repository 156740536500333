export const BORDER = {
  ALL: 'ALL',
  EACH: 'EACH',
} as const;

export type BorderType = keyof typeof BORDER;

export type BorderRadiusStyle = {
  borderType: BorderType;
  leftTopRadius: number;
  rightTopRadius: number;
  rightDownRadius: number;
  leftDownRadius: number;
};

export const DefaultBorder: BorderRadiusStyle = {
  borderType: BORDER.ALL,
  leftTopRadius: 0,
  rightTopRadius: 0,
  rightDownRadius: 0,
  leftDownRadius: 0,
};
