'use client';

import { FC, useContext, useEffect, useState } from 'react';
import ITabOption from 'ui/common/tab/define/ITabOption';
import RecruitSetting from 'ui/component/recruiter/recruitSetting/RecruitSetting';
import SolutionContext from '../../../../context/SolutionContext';
import RecruitSettingContext from '../../../../context/recruiter/RecruitSettingContext';
import RecruitSettingInjectedContext from '../../../../context/recruiter/RecruitSettingInjectedContext';
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import IViewContents from '../../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../../interface/contents/recruitment/IRecruitment';
import { RECRUITMENT_VIEW } from '../../../../interface/contents/recruitment/IRecruitmentStyle';

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
}

const RecruitViewSetting: FC<IProps> = ({ viewMode, viewRecruitment, isPreview }) => {
  const {
    keyword,
    tag,
    careerType,
    workingArea,
    jobGroup,
    submissionStatus,
    setKeyword,
    setTag,
    setCareerType,
    setJobGroup,
    setWorkingArea,
    setSubmissionStatus,
  } = useContext(RecruitSettingContext);

  const { queryGetSettingData, getSetting } = useContext(RecruitSettingInjectedContext);

  const { solution } = useContext(SolutionContext);
  const [tagOptionList, setTagOptionList] = useState<ITabOption<string>[]>([]);
  const { multiLanguageText } = useMultiLanguage();
  const DEFAULT_TAB_OPTION = { value: '', label: multiLanguageText('전체', 'ALL') };

  useEffect(() => {
    getSetting?.();
  }, [getSetting]);

  useEffect(() => {
    if (!queryGetSettingData || !queryGetSettingData.tagTypeList) return;
    if (queryGetSettingData.tagTypeList.length === 0) return;
    const currentTagList = queryGetSettingData.tagTypeList.find(
      ({ tagType }) => tagType === queryGetSettingData.tagType
    );

    if (!currentTagList) {
      setTagOptionList([DEFAULT_TAB_OPTION]);
      return;
    }

    setTagOptionList([
      DEFAULT_TAB_OPTION,
      ...currentTagList.tagList.map(({ tagSn, tagName }) => ({
        label: tagName,
        value: tagSn.toString(),
      })),
    ]);
    setTag([]);
  }, [queryGetSettingData.tagTypeList]);

  return (
    <RecruitSetting
      solution={solution}
      viewMode={viewMode}
      viewType={viewRecruitment.style.viewType ?? RECRUITMENT_VIEW.LIST}
      selectorList={{
        tagOptionList,
        ...queryGetSettingData,
      }}
      selectedList={{
        keyword,
        tag,
        jobGroup,
        careerType,
        workingArea,
        submissionStatus,
      }}
      setSelectedList={{
        keyword: setKeyword,
        tag: setTag,
        jobGroup: setJobGroup,
        careerType: setCareerType,
        workingArea: setWorkingArea,
        submissionStatus: setSubmissionStatus,
      }}
      readOnly={isPreview}
    />
  );
};

export default RecruitViewSetting;
