import { FC } from 'react';
import styles from './ViewGroupBackground.module.scss';
import classNames = require('classnames/bind');
import IViewGroup from '../../../interface/group/IViewGroup';
import { GROUP_OPTION } from '../../../interface/group/IGroup';
import { IGroupImageStyle } from '../../../interface/group/IGroupStyle';
import { ViewModeType } from '../../../interface/common/ViewMode';
import GroupBackgroundColor from './GroupBackgroundColor';
import GroupBackgroundImage from './GroupBackgroundImage';

const cx = classNames.bind(styles);

export interface IViewGroupBackgroundProps {
  viewMode?: ViewModeType;
  typedStyle: IGroupImageStyle;
  viewGroup: IViewGroup;
  isCapture?: boolean;
}
const ViewGroupBackground: FC<IViewGroupBackgroundProps> = ({ viewGroup, typedStyle, viewMode, isCapture }) => {
  if (viewGroup?.style?.backgroundType === GROUP_OPTION.COLOR) return <GroupBackgroundColor viewGroup={viewGroup} />;

  if (viewGroup?.style?.backgroundType === GROUP_OPTION.IMAGE)
    return (
      <GroupBackgroundImage viewGroup={viewGroup} viewMode={viewMode} isCapture={isCapture} typedStyle={typedStyle} />
    );

  return null;
};

const ViewGroupBackgroundWrapper = (props: IViewGroupBackgroundProps) => {
  return (
    <div className={cx('background-wrapper')}>
      <div className={cx('background')}>
        <ViewGroupBackground {...props} />
      </div>
    </div>
  );
};

export default ViewGroupBackgroundWrapper;
