import { Descendant, Element } from 'slate';
import { CustomText } from '../common/textEditor/textEditor.type';

export const extractSlateText = (slateValue: Descendant[]) => {
  return slateValue.reduce((acc1, _cur1) => {
    const cur1 = _cur1 as Element;
    const children = cur1.children as CustomText[];
    return acc1.concat(children.reduce((acc2, cur2) => acc2.concat((cur2 as CustomText).text), ''));
  }, '');
};

export type StringifiedSlateValue = string;
export const parseSlateValue = (value: StringifiedSlateValue | Descendant[] | null): Descendant[] => {
  return typeof value === 'string' ? JSON.parse(value) : value;
};
