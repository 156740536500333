import { useMemo } from 'react';
import { useBackgroundFile, UseBackgroundFileArgs } from 'ui/hook/useBackgroundFile';
import { ViewModeType } from 'ui/interface/common/ViewMode';
import { BANNER_TYPE } from 'ui/interface/layout/banner/banner.constant';
import IBanner from 'ui/interface/layout/banner/IBanner';

interface Params {
  banner: IBanner;
  currentIndex?: number;
  viewMode: ViewModeType;
}
export const useLayoutBannerBackgroundFile = ({ banner, currentIndex, viewMode }: Params) => {
  const backgroundFileArgs = useMemo<UseBackgroundFileArgs>(() => {
    if (banner.type === BANNER_TYPE.BASIC) {
      return {
        desktopFileUid: banner.bannerBasic.desktopImage?.fileUid ?? '',
        desktopFileUrl: banner.bannerBasic.desktopImage?.fileUrl ?? '',
        mobileFileUid: banner.bannerBasic.mobileImage?.fileUid ?? '',
        mobileFileUrl: banner.bannerBasic.mobileImage?.fileUrl ?? '',
      };
    }

    return {
      desktopFileUid: banner.bannerSlide.slideImageList[currentIndex!]?.desktopImage?.fileUid ?? '',
      desktopFileUrl: banner.bannerSlide.slideImageList[currentIndex!]?.desktopImage?.fileUrl ?? '',
      mobileFileUid: banner.bannerSlide.slideImageList[currentIndex!]?.mobileImage?.fileUid ?? '',
      mobileFileUrl: banner.bannerSlide.slideImageList[currentIndex!]?.mobileImage?.fileUrl ?? '',
    };
  }, [banner, currentIndex]);

  const backgroundFileUrl = useBackgroundFile({
    ...backgroundFileArgs,
    viewMode,
  });

  return { ...backgroundFileUrl, ...backgroundFileArgs };
};
