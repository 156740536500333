import classnames from 'classnames/bind';
import { FC, useContext, useRef } from 'react';
import Button from '../../../common/button/Button';
import Icon from '../../../common/icon/Icon';
import Pagination from '../../../common/pagination/Pagination';
import TextFieldBase from '../../../common/textfield/TextFieldBase';
import { APPS } from '../../../constants/app.constant';
import { COLORS } from '../../../constants/colors';
import { isOnOff, ON } from '../../../constants/common';
import { LANGUAGE } from '../../../constants/language';
import BoardInjectedContext from '../../../context/board/BoardInjectedContext';
import BoardSettingContext from '../../../context/board/BoardSettingContext';
import useDebouncedInput from '../../../hook/useDebouncedInput';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import usePagination from '../../../hook/usePagination';
import { HasOption } from '../../../interface/common/hasOption';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { IBoard } from '../../../interface/contents/board/IBoard';
import IViewContents from '../../../interface/contents/IViewContents';
import PaginationVO from '../../../model/vo/PaginationVO';
import { APP_TYPE } from '../../../util/app';
import NoResult from '../../common/NoResult';
import useViewBoard, { IViewBoardProps } from './useViewBoard';
import styles from './ViewBoard.module.scss';
import ViewBoardInner from './ViewBoardInner';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  viewBoard: IViewContents<IBoard>;
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
  directedInjectedViewBoardProps?: IViewBoardProps;
}

const ViewBoard: FC<IProps & HasOption> = ({
  viewMode,
  viewBoard,
  isEdit,
  isPreview,
  previewTabType,
  directedInjectedViewBoardProps,
  options,
}) => {
  const { multiLanguageText } = useMultiLanguage();
  const isEditor = APP_TYPE === APPS.EDITOR;
  const contextInjectedViewBoardProps = useContext(BoardInjectedContext);
  const { selectedCategory } = useContext(BoardSettingContext);
  const injectedViewBoardProps = directedInjectedViewBoardProps ?? contextInjectedViewBoardProps;
  const {
    postThumbnailPostList: _getArticleList,
    articleList,
    articlePagination,
  } = useViewBoard({
    viewBoardProps: injectedViewBoardProps,
    viewBoard,
  });
  const languageType = options && options['languageType'];

  const searchInputShow = isOnOff(viewBoard.style?.searchBarStatus ?? ON);
  const searchInputDisabled = isEdit;
  const { keywordForShow, filteredKeyword, handleChangeKeyword } = useDebouncedInput();

  const getArticleList = (page: number, keyword: string) => {
    if (!_getArticleList) return;
    _getArticleList({
      pageable: {
        page,
        size: viewBoard.value.size,
      },
      filter: {
        categoryNameList: selectedCategory
          ? [selectedCategory]
          : viewBoard.value.categoryNameList.length > 0
          ? viewBoard.value.categoryNameList
          : null,
        keyword,
      },
    });
  };

  const { changeUrlPage } = usePagination({
    pageName: 'articlePage',
    totalPages: articlePagination.totalPages,
    getList: (page: number) => getArticleList(page, filteredKeyword),
    refetchDependencies: [selectedCategory, viewBoard.value, _getArticleList],
  });

  const { viewType } = viewBoard.style;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={cx('content')} ref={containerRef}>
      {searchInputShow && (
        <TextFieldBase
          size={'md'}
          className={cx('search-input')}
          placeholder={multiLanguageText('제목과 내용으로 검색할 수 있어요.', 'Search')}
          beforeInput={
            <Icon color={searchInputDisabled ? COLORS.gray400 : COLORS.gray600} size={24} name={'search_line'} />
          }
          value={keywordForShow}
          onChange={(e) => {
            const value = e.target.value;
            handleChangeKeyword(value, () => getArticleList(1, value), 200);
          }}
          disabled={searchInputDisabled}
        />
      )}
      <div className={cx('articles-container')}>
        {articleList.length > 0 ? (
          <>
            <ViewBoardInner
              viewType={viewType}
              viewMode={viewMode}
              articleList={articleList}
              viewBoard={viewBoard}
              isPreview={isPreview}
              previewTabType={previewTabType}
              isEdit={isEdit}
              containerRef={containerRef}
              options={options}
            />
            <div className={cx('pagination')}>
              <Pagination
                showOnlyPagination={viewMode === VIEW_MODE.MOBILE}
                showDropdown={false}
                pageHandler={({ page }) => !isEdit && changeUrlPage(page)}
                page={
                  new PaginationVO({
                    pageSize: articlePagination.size,
                    ...articlePagination,
                  })
                }
                beforeCountWord={languageType === LANGUAGE.KOR ? undefined : 'Total'}
                afterCountWord={languageType === LANGUAGE.KOR ? undefined : ''}
              />
            </div>
          </>
        ) : (
          <div className={cx('no-article-result')}>
            <NoResult
              className={cx('no-result')}
              title={'등록한 게시글이 없어요.'}
              subTitle={isEditor ? '게시글을 등록하여 우리 회사를 브랜딩해보세요!' : ''}
              padding={false}
            />
            {isEditor && (
              <Button
                className={cx('article-register-button')}
                size={'sm'}
                rightIcon={'open_in_new_line'}
                onClick={() => window.open('/post', '_blank')}
              >
                등록하기
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBoard;
