import { useQueryGetBrandFontListV1 } from '@queries/brand/font';
import { useMemo } from 'react';
import { IViewFontProps } from 'ui/hook/brand/useViewFont';

const useFontContext = () => {
  const { refetch: getBrandFontList, data: brandFontList } = useQueryGetBrandFontListV1();

  const viewFontProps: IViewFontProps = useMemo(
    () => ({
      getBrandFontList,
      brandFontList,
    }),
    [getBrandFontList, brandFontList]
  );

  return {
    viewFontProps,
  };
};

export default useFontContext;
