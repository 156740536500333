import classNames from 'classnames/bind';
import { FC, useMemo, useState } from 'react';
import Button from '../../../common/button/Button';
import DropdownWithOnClick from '../../../common/dropdownWithOnClick/Dropdown';
import Tab from '../../../common/tab/Tab';
import ITabOption from '../../../common/tab/define/ITabOption';
import { LANGUAGE } from '../../../constants/language';
import { CAREER_DEFINITION, FILTER_DEFINITION, SUBMISSION_STATUS_DEFINITION } from '../../../constants/position/common';
import { SOLUTION, SolutionType } from '../../../constants/solution/solution.constant';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { RECRUITMENT_VIEW, TRecruitmentView } from '../../../interface/contents/recruitment/IRecruitmentStyle';
import {
  IJobGroupTypeList,
  IWorkingAreaList,
  TCareerType,
  TSubmissionStatusType,
} from '../../../interface/recruit/IRecruitSetting';
import style from './RecruitSetting.module.scss';
import KeywordFilter from './keywordFilter/keywordFilter';

const cx = classNames.bind(style);

export interface IRecruitSettingSelectorList {
  tagOptionList: ITabOption[];
  filterTypeList: string[];
  jobGroupList?: IJobGroupTypeList[];
  careerTypeList?: TCareerType[];
  workingAreaList?: IWorkingAreaList[];
  submissionStatusList?: TSubmissionStatusType[];
}

export interface IRecruitSettingSelectedList {
  keyword: string;
  tag: string[];
  jobGroup: number[];
  careerType: TCareerType[];
  workingArea: number[];
  submissionStatus: TSubmissionStatusType[];
}

export interface IRecruitSettingSetSelectedList {
  keyword: (value: string) => void;
  tag: (val: string[]) => void;
  jobGroup: (value: number[]) => void;
  careerType: (value: TCareerType[]) => void;
  workingArea: (value: number[]) => void;
  submissionStatus: (value: TSubmissionStatusType[]) => void;
}

interface IRecruitSettingProps<OptionValues extends string = string> {
  solution?: SolutionType;
  viewMode?: ViewModeType;
  viewType?: TRecruitmentView;
  selectorList?: IRecruitSettingSelectorList;
  selectedList?: IRecruitSettingSelectedList;
  setSelectedList?: IRecruitSettingSetSelectedList;
  readOnly?: boolean;
}

const RecruitSetting: FC<IRecruitSettingProps> = ({
  solution = SOLUTION.JOBDA,
  viewMode = 'PC',
  viewType = RECRUITMENT_VIEW.LIST,
  selectorList,
  selectedList,
  setSelectedList,
  readOnly = false,
}) => {
  const {
    filterTypeList = [],
    tagOptionList = [],
    jobGroupList = [],
    careerTypeList = [],
    workingAreaList = [],
    submissionStatusList = [],
  } = selectorList || {};

  const {
    keyword = '',
    tag = [],
    jobGroup = [],
    workingArea = [],
    careerType = [],
    submissionStatus = [],
  } = selectedList || {};

  const {
    keyword: setKeyword,
    tag: setSelectedTagList,
    jobGroup: setSelectedJobGroupList,
    workingArea: setSelectedWorkingAreaList,
    careerType: setSelectedCareerTypeList,
    submissionStatus: setSelectedSubmissionStatusList,
  } = setSelectedList || {};

  /**
   *   keywordInput: 자음이나 모음만 있을 땐 검색이 안되도록 하기위해 view를 위한 데이터
   *   keyword: 실제 검색에 사용되는 데이터
   */
  const [keywordInput, setKeywordInput] = useState('');

  const handleInputKeywordInput = (text: string) => {
    setKeywordInput(text);
  };

  const handleClickReset = () => {
    setKeyword?.('');
    handleInputKeywordInput('');
    setSelectedTagList?.([]);
    setSelectedJobGroupList?.([]);
    setSelectedCareerTypeList?.([]);
    setSelectedWorkingAreaList?.([]);
    setSelectedSubmissionStatusList?.([]);
  };

  const tabSwipeOption = useMemo(() => {
    return { enabled: viewMode === 'MOBILE' };
  }, [viewMode]);

  const { multiLanguageText, languageType } = useMultiLanguage();

  return (
    <>
      {tagOptionList.length > 1 && (
        <div className={cx('tags-wrapper', viewMode)}>
          <Tab
            type={viewType === RECRUITMENT_VIEW.KANBAN && viewMode === VIEW_MODE.PC ? 'inline-label' : 'primary'}
            options={tagOptionList}
            value={tag.length > 0 ? tag[0] : ''}
            className={cx('tab')}
            onChange={(value) => {
              const newTag = value === '' ? [] : [value];
              setSelectedTagList?.(newTag);
            }}
            swipeOption={tabSwipeOption}
          />
        </div>
      )}
      <div className={cx('filter-list', { full: filterTypeList.length === 4 }, viewMode)}>
        <KeywordFilter
          className={cx('keyword-filter')}
          solution={solution}
          viewMode={viewMode}
          keyword={keyword}
          keywordInput={keywordInput}
          handleInputKeyword={setKeyword}
          handleInputKeywordInput={handleInputKeywordInput}
          disabled={readOnly}
        />
        {filterTypeList.includes('JOB_GROUP_AND_JOB') && (
          <div className={cx('filter')} key={'JOB_GROUP_AND_JOB'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['JOB_GROUP_AND_JOB'][languageType]}
              options={jobGroupList.map((value) => {
                return {
                  value: value.sn,
                  name: multiLanguageText(value.name, value.englishName ?? value.name),
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={setSelectedJobGroupList}
              value={jobGroup}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = jobGroupList.filter(({ sn }) => jobGroup.includes(sn));
                if (selectedList.length === 0) return FILTER_DEFINITION['JOB_GROUP_AND_JOB'][languageType];
                if (selectedList.length === 1) {
                  switch (languageType) {
                    case LANGUAGE.KOR:
                      return selectedList[0].name;
                    case LANGUAGE.ENG:
                      return selectedList[0].englishName ?? selectedList[0].name;
                  }
                }
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${selectedList[0].name} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${selectedList[0].englishName ?? selectedList[0].name} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('CAREER_TYPE') && (
          <div className={cx('filter')} key={'CAREER_TYPE'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['CAREER_TYPE'][languageType]}
              options={careerTypeList.map((value) => {
                return {
                  value: value,
                  name: CAREER_DEFINITION[value][languageType],
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={setSelectedCareerTypeList}
              value={careerType}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = careerTypeList.filter((value) => careerType.includes(value));
                if (selectedList.length === 0) return FILTER_DEFINITION['CAREER_TYPE'][languageType];
                if (selectedList.length === 1) return CAREER_DEFINITION[selectedList[0]][languageType];
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${CAREER_DEFINITION[selectedList[0]][LANGUAGE.KOR]} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${CAREER_DEFINITION[selectedList[0]][LANGUAGE.ENG]} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('WORKING_AREA') && (
          <div className={cx('filter')} key={'WORKING_AREA'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['WORKING_AREA'][languageType]}
              options={workingAreaList.map((value) => {
                return {
                  value: value.sn,
                  name: multiLanguageText(value.name, value.englishName ?? value.name),
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={setSelectedWorkingAreaList}
              value={workingArea}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = workingAreaList.filter(({ sn }) => workingArea.includes(sn));
                if (selectedList.length === 0) return FILTER_DEFINITION['WORKING_AREA'][languageType];
                if (selectedList.length === 1) return selectedList[0].name;
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${selectedList[0].name} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${selectedList[0].englishName ?? selectedList[0].name} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('SUBMISSION_STATUS') && (
          <div className={cx('filter')} key={'SUBMISSION_STATUS'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['SUBMISSION_STATUS'][languageType]}
              options={submissionStatusList.map((value) => {
                return {
                  value: value,
                  name: SUBMISSION_STATUS_DEFINITION[value][languageType],
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={setSelectedSubmissionStatusList}
              value={submissionStatus}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = submissionStatusList.filter((value) => submissionStatus.includes(value));
                if (selectedList.length === 0) return FILTER_DEFINITION['SUBMISSION_STATUS'][languageType];
                if (selectedList.length === 1) return SUBMISSION_STATUS_DEFINITION[selectedList[0]][languageType];
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${SUBMISSION_STATUS_DEFINITION[selectedList[0]][LANGUAGE.KOR]} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${SUBMISSION_STATUS_DEFINITION[selectedList[0]][LANGUAGE.ENG]} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        <Button
          leftIcon={'reset_line'}
          variant={'text'}
          size={'sm'}
          className={cx('reset', viewMode)}
          onClick={handleClickReset}
        >
          {multiLanguageText('초기화', 'Reset')}
        </Button>
      </div>
    </>
  );
};

export default RecruitSetting;
