import { useContext, useEffect, useState } from 'react';
import ITabOption from '../../../../common/tab/define/ITabOption';
import RecruitSettingContext from '../../../../context/recruiter/RecruitSettingContext';
import RecruitSettingInjectedContext from '../../../../context/recruiter/RecruitSettingInjectedContext';
import SolutionContext from '../../../../context/SolutionContext';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import RecruitSideSetting from '../../../recruiter/recruitSideSetting/RecruitSideSetting';

interface IProps {
  viewMode?: ViewModeType;
  tagFilterName: string;
}

const RecruitViewSideSetting = ({ viewMode, tagFilterName }: IProps) => {
  const {
    tag,
    keyword,
    careerType,
    workingArea,
    jobGroup,
    submissionStatus,
    setTag,
    setKeyword,
    setCareerType,
    setJobGroup,
    setWorkingArea,
    setSubmissionStatus,
  } = useContext(RecruitSettingContext);

  const { queryGetSettingData, getSetting } = useContext(RecruitSettingInjectedContext);

  const { solution } = useContext(SolutionContext);
  const [tagOptionList, setTagOptionList] = useState<ITabOption[]>([]);

  useEffect(() => {
    getSetting?.();
  }, [getSetting]);

  useEffect(() => {
    if (!queryGetSettingData?.tagTypeList) return;
    if (queryGetSettingData.tagTypeList.length === 0) return;

    const currentTagList = queryGetSettingData.tagTypeList.find(
      ({ tagType }) => tagType === queryGetSettingData.tagType
    );

    setTagOptionList(
      (currentTagList?.tagList ?? []).map(({ tagSn, tagName }) => ({
        label: tagName,
        value: tagSn.toString(),
      }))
    );
    setTag([]);
  }, [queryGetSettingData.tagTypeList]);

  return (
    <RecruitSideSetting
      solution={solution}
      viewMode={viewMode}
      selectorList={{
        tagOptionList,
        ...queryGetSettingData,
      }}
      selectedList={{
        tag,
        keyword,
        jobGroup,
        careerType,
        workingArea,
        submissionStatus,
      }}
      setSelectedList={{
        tag: setTag,
        keyword: setKeyword,
        jobGroup: setJobGroup,
        careerType: setCareerType,
        workingArea: setWorkingArea,
        submissionStatus: setSubmissionStatus,
      }}
      tagFilterNameProps={{
        isEditable: false,
        isEditing: false,
        inputValue: tagFilterName,
        handleChangeInput: () => {},
        handleClickEditIcon: () => {},
        changeToReadMode: () => {},
      }}
    />
  );
};

export default RecruitViewSideSetting;
