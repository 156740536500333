import IViewLayout from '../../../interface/layout/IViewLayout';
import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewSlider.module.scss';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import useIntersectionObserver from '../../../hook/useIntersectionObserver';
import useScrollToBottom from '../../../hook/useScrollToBottom';
import { HasOption } from '../../../interface/common/hasOption';
import ViewLayoutBackground from '../ViewLayoutBackground/ViewLayoutBackground';
import ViewSliderMobile from './ViewSliderMobile/ViewSliderMobile';
import ViewSliderPc from './ViewSliderPc/ViewSliderPc';
const cx = classnames.bind(styles);

interface IViewSliderProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

export const AUTO_SLIDER_DURATION_SECONDS = 4;

const ViewSlider: FC<IViewSliderProps & HasOption> = ({ viewMode, viewLayout, isCapture, isPreview, options }) => {
  // 스크롤 hook
  const { isIntersection, setIsIntersection } = useIntersectionObserver({
    idList: [viewLayout.id],
  });

  // 바닥 감지 hook
  useScrollToBottom({
    onBottom: () => setIsIntersection(true),
  });

  const topBottomPadding = viewLayout.slider!.style.topBottomPadding;

  return (
    <div id={viewLayout.id} className={cx('container')}>
      <ViewLayoutBackground viewLayout={viewLayout} viewMode={viewMode} isCapture={isCapture} />
      <div
        className={cx('sliderWrap', { fadeIn: !isCapture && isIntersection }, { isCapture })}
        style={{ paddingTop: topBottomPadding, paddingBottom: topBottomPadding }}
      >
        {viewMode !== VIEW_MODE.MOBILE ? (
          <ViewSliderPc
            viewMode={viewMode}
            viewLayout={viewLayout}
            options={options}
            isPreview={isPreview}
            isCapture={isCapture}
          />
        ) : (
          <ViewSliderMobile
            viewMode={viewMode}
            viewLayout={viewLayout}
            options={options}
            isPreview={isPreview}
            isCapture={isCapture}
          />
        )}
      </div>
    </div>
  );
};

export default ViewSlider;
