import { FC } from 'react';
import IViewLayout from '../../../interface/layout/IViewLayout';

interface IProps {
  viewLayout: IViewLayout;
}
const LayoutBackgroundColor: FC<IProps> = ({ viewLayout }) => {
  return <div style={{ width: '100%', height: '100%', backgroundColor: viewLayout.style.backgroundColor }} />;
};

export default LayoutBackgroundColor;
