import { useEffect, useState } from 'react';

// 카카오맵 초기화 상태 관리
let isKakaoInitialized = false;

// 카카오맵 스크립트 URL 생성
const getKakaoMapScriptUrl = (): string => {
  const apiKey = process.env.NEXT_PUBLIC_KAKAO_APP_KEY;
  if (!apiKey) {
    console.error('카카오맵 API 키가 설정되지 않았습니다.');
    throw new Error('NEXT_PUBLIC_KAKAO_APP_KEY가 정의되지 않았습니다.');
  }
  return `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false&libraries=services,drawing`;
};

// 이미 카카오맵이 초기화되었는지 확인
const isKakaoMapReady = (): boolean => {
  return isKakaoInitialized;
};

// 카카오맵 스크립트가 로드되었는지 확인
const isKakaoScriptLoaded = (): boolean => {
  return Boolean(window.kakao?.maps?.load);
};

// 카카오맵 스크립트 태그가 DOM에 존재하는지 확인
const isKakaoScriptTagExists = (): boolean => {
  return Boolean(document.querySelector('script[src*="dapi.kakao.com/v2/maps/sdk.js"]'));
};

// 카카오맵 초기화 (load 메서드 호출)
const initializeKakaoMap = (): Promise<void> => {
  return new Promise((resolve) => {
    window.kakao.maps.load(() => {
      isKakaoInitialized = true;
      resolve();
    });
  });
};

// 이미 로드된 상태에서 초기화 수행
const handleAlreadyLoadedScript = (): Promise<void> => {
  return initializeKakaoMap();
};

// DOM에 script 태그가 있지만 아직 로드 중인 경우 처리
const handleExistingScriptTag = (timeoutMs: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    const checkInterval = setInterval(() => {
      if (window.kakao?.maps) {
        clearInterval(checkInterval);
        initializeKakaoMap().then(resolve);
      }
    }, 100);

    // 타임아웃 설정
    setTimeout(() => {
      clearInterval(checkInterval);
      reject(new Error('카카오맵 로드 타임아웃'));
    }, timeoutMs);
  });
};

// 새 스크립트 생성 및 로드
const createAndLoadScript = (timeoutMs: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.src = getKakaoMapScriptUrl();

      // 타임아웃 설정
      const timeoutId = setTimeout(() => {
        reject(new Error('카카오맵 로드 타임아웃'));
      }, timeoutMs);

      script.onload = () => {
        clearTimeout(timeoutId);
        initializeKakaoMap().then(resolve);
      };

      script.onerror = () => {
        clearTimeout(timeoutId);
        reject(new Error('카카오맵 스크립트 로드 실패'));
      };

      document.head.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
};

// 카카오맵 스크립트 로드 메인 함수
const loadKakaoMapScript = (timeoutMs = 10000): Promise<void> => {
  // 이미 초기화된 경우
  if (isKakaoMapReady()) {
    return Promise.resolve();
  }

  // 이미 로드되어 있는 경우
  if (isKakaoScriptLoaded()) {
    return handleAlreadyLoadedScript();
  }

  // 스크립트 태그가 이미 존재하는 경우 (아직 로드 중)
  if (isKakaoScriptTagExists()) {
    return handleExistingScriptTag(timeoutMs);
  }

  // 새 스크립트 생성 및 로드
  return createAndLoadScript(timeoutMs);
};

// 카카오맵 로드 상태를 관리하는 훅
export const useLoadKakaoScript = (): [boolean, Error | null] => {
  const [isMapReady, setIsMapReady] = useState(isKakaoInitialized);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // 서버 사이드 렌더링 대응
    if (typeof window === 'undefined') return;

    if (isKakaoInitialized) {
      setIsMapReady(true);
      return;
    }

    loadKakaoMapScript()
      .then(() => {
        setIsMapReady(true);
      })
      .catch((err: Error) => {
        console.error('카카오맵 로드 오류:', err.message);
        setError(err);
      });
  }, []);

  return [isMapReady, error];
};
