import classnames from 'classnames/bind';
import { FC, Suspense, useRef } from 'react';
import { LoadingComponent } from '../../context/loading/Loading';
import { ViewModeType } from '../../interface/common/ViewMode';
import { HasOption } from '../../interface/common/hasOption';
import { CONTENTS, IContentsType } from '../../interface/contents/IContents';
import IViewContents from '../../interface/contents/IViewContents';
import { IAccordion } from '../../interface/contents/accordion/IAccordion';
import { IBoard } from '../../interface/contents/board/IBoard';
import { IButton } from '../../interface/contents/button/IButton';
import { IDivider } from '../../interface/contents/divider/IDivider';
import { IImage } from '../../interface/contents/image/IImage';
import { IMap } from '../../interface/contents/map/IMap';
import IMargin from '../../interface/contents/margin/IMargin';
import { IRecruitment } from '../../interface/contents/recruitment/IRecruitment';
import { IText } from '../../interface/contents/text/IText';
import { IVideo } from '../../interface/contents/video/IVideo';
import styles from './ViewContents.module.scss';
import ViewAccordion from './accordion/ViewAccordion';
import ViewBoard from './board/ViewBoard';
import ViewButton from './button/ViewButton';
import ViewDivider from './divider/ViewDivider';
import ViewImage from './image/ViewImage';
import ViewMap from './map/ViewMap';
import ViewMargin from './margin/ViewMargin';
import ViewRecruitment from './recruitment/ViewRecruitment';
import ViewText from './text/ViewText';
import ViewVideo from './video/ViewVideo';
const cx = classnames.bind(styles);

interface IViewContentsProps {
  viewMode?: ViewModeType;
  viewContents: IViewContents<IContentsType>;
  isPreview?: boolean;
  previewTabType?: ViewModeType;
  isEdit?: boolean;
}

const ViewContents: FC<IViewContentsProps & HasOption> = ({
  viewMode,
  viewContents,
  isPreview,
  isEdit,
  previewTabType,
  options,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getContentsEl = () => {
    switch (viewContents.type) {
      case CONTENTS.BUTTON:
        return (
          <ViewButton
            viewMode={viewMode}
            viewButton={viewContents as IViewContents<IButton>}
            isPreview={isPreview}
            isEdit={isEdit}
            options={options}
          />
        );
      case CONTENTS.IMAGE:
        return (
          <ViewImage
            viewMode={viewMode}
            viewImage={viewContents as IViewContents<IImage>}
            isPreview={isPreview}
            isEdit={isEdit}
            options={options}
          />
        );
      case CONTENTS.TEXT:
        return (
          <ViewText
            viewMode={viewMode}
            viewText={viewContents as IViewContents<IText>}
            isPreview={isPreview}
            options={options}
          />
        );
      case CONTENTS.RECRUITMENT:
        return (
          <ViewRecruitment
            viewMode={viewMode}
            viewRecruitment={viewContents as IViewContents<IRecruitment>}
            isPreview={isPreview}
            isEdit={isEdit}
          />
        );
      case CONTENTS.ACCORDION:
        return (
          <ViewAccordion
            viewMode={viewMode}
            viewAccordion={viewContents as IViewContents<IAccordion>}
            options={options}
          />
        );
      case CONTENTS.BOARD:
        return (
          <ViewBoard
            viewMode={viewMode}
            viewBoard={viewContents as IViewContents<IBoard>}
            isPreview={isPreview}
            previewTabType={previewTabType}
            isEdit={isEdit}
            options={options}
          />
        );
      case CONTENTS.MARGIN:
        return (
          <ViewMargin viewMargin={viewContents as IViewContents<IMargin>} isPreview={isPreview} viewMode={viewMode} />
        );
      case CONTENTS.LINE:
        return <ViewDivider viewDivider={viewContents as IViewContents<IDivider>} viewMode={viewMode} />;
      case CONTENTS.VIDEO:
        return (
          <ViewVideo
            viewVideo={viewContents as IViewContents<IVideo>}
            viewMode={viewMode}
            wrapperRef={wrapperRef}
            refGroupId={viewContents.parentId}
          />
        );
      case CONTENTS.MAP:
        return <ViewMap mapContent={viewContents as IViewContents<IMap>} viewMode={viewMode} />;
      default:
        return null;
    }
  };

  return (
    <div className={cx('wrap')} ref={wrapperRef}>
      <Suspense fallback={<LoadingComponent />}>{getContentsEl()}</Suspense>
    </div>
  );
};

export default ViewContents;
