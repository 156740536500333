import { FC, ReactNode } from 'react';
import BoardProvider from './board/BoardContext';
import FontProvider from './font/FontContext';
import RecruitProvider from './recruiter/RecruitContext';

const ContentsProvider: FC<{ children: ReactNode }> = (props) => {
  return (
    <BoardProvider>
      <RecruitProvider>
        <FontProvider>{props.children}</FontProvider>
      </RecruitProvider>
    </BoardProvider>
  );
};

export default ContentsProvider;
