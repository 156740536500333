export enum SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  CUSTOM = 'CUSTOM',
}
export type SizeType = SIZE;

export type SizeStyle = {
  type: SizeType;
  width: number;
  height: number;
};
