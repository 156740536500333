import { getBrandFontList } from '@api/brand/font';
import { BrandQueryKey } from '@queries/brand';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IGetBrandFontResponse } from 'ui/interface/brand/font/getBrandFont';

// 브랜드 폰트 리스트 조회
export const useQueryGetBrandFontListV1 = (): UseQueryResult<IGetBrandFontResponse, AxiosError> => {
  return useQuery({
    queryKey: BrandQueryKey.font(),
    queryFn: getBrandFontList,
  });
};
