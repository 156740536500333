'use client';

import { useQueryGetCompanyPrivacyPolicy, useQueryGetCompanyPrivacyPolicyList } from '@api/company/privacyPolicy.api';
import { getDesignV2 } from '@api/design';
import { useQueryGetPopup } from '@api/popup/popup.api';
import QnAWrapper from '@component/qna/QnAWrapper';
import ResumeUpdatePage from '@component/resume/ResumeUpdatePage';
import { useApplyBrandFont } from '@hook/brand/useApplyBrandFont';
import useScrollPositionTop from '@hook/useScrollPositionTop';
import useViewData from '@hook/useViewData';
import useViewPage from '@hook/useViewPage';
import { useQueryGetDesignMultiLanguageDefault } from '@queries/design';
import { channelTalkActiveStatusAtom } from '@recoil/channelTalkActiveStatusAtom';
import { isMobileAtom } from '@recoil/viewAtom';
import classNames from 'classnames/bind';
import { notFound, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';
import ViewFooter from 'ui/component/footer/ViewFooter';
import ViewHeader from 'ui/component/header/ViewHeader';
import ViewPage from 'ui/component/page/ViewPage';
import PopupList, { PopupListPropType } from 'ui/component/popup/PopupList';
import { LANGUAGE, Language } from 'ui/constants/language';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { useUrl } from 'ui/hook/useUrl';
import useUrlRouter from 'ui/hook/useUrlRouter';
import useViewMode from 'ui/hook/useViewMode';
import { SOLUTION_TYPE } from 'ui/util/solution';
import style from './Index.module.scss';

const cx = classNames.bind(style);

const Index = () => {
  const { getPathname, getSearchParam, hasSearchParam, buildUrl } = useUrl();
  const [showPopupList, setShowPopupList] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { viewMode, handleResize } = useViewMode({ ref });
  const { viewHeader, viewPage, viewFooter, pageMetadataHooks, mainPageUrlPath, error, title, isSuccess } =
    useViewData();
  const router = useRouter();
  const pathname = getPathname();
  const viewType = getSearchParam('viewType');
  const languageType = getSearchParam<Language>('languageType') ?? LANGUAGE.KOR;
  const popupList = useQueryGetPopup();
  const isMobile = useRecoilValue(isMobileAtom);
  const { viewPageProps } = useViewPage();
  const { data: privatePolicyList } = useQueryGetCompanyPrivacyPolicyList();
  const { data: queryGetDesignMultiLanguageDefaultData } = useQueryGetDesignMultiLanguageDefault();
  const channelTalkActiveStatus = useRecoilValue(channelTalkActiveStatusAtom);
  const { useStatus } = queryGetDesignMultiLanguageDefaultData ?? {
    useStatus: 'OFF',
  };
  const pageLinkList = pageMetadataHooks?.pageList ?? [];
  const { handleUrlClick } = useUrlRouter();

  useApplyBrandFont();
  useScrollPositionTop();

  useEffect(() => {
    handleResize();
  }, []);

  // home 페이지가 jobs(채용 공고로 설정된 경우) 채용공고로 이동
  useEffect(() => {
    if (pathname === '/' && mainPageUrlPath === 'jobs') {
      const newUrl = buildUrl('/jobs')
        .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
        .getResult();
      router.push(newUrl);
    }
  }, [mainPageUrlPath]);

  // 미게시 채용사이트 404 이동 TODO error 타입 선언, any 삭제
  useEffect(() => {
    const data = error?.response?.data as any;

    if (data?.code === 'NotFoundPostedDesignException') {
      notFound();
      return;
    }
  }, [error]);

  useEffect(() => {
    if (!pathname || !mainPageUrlPath) return;
    if (pathname === mainPageUrlPath) setShowPopupList(true);
  }, [pathname, mainPageUrlPath]);

  const handleLanguageSwitch = async (targetLanguageType: Language) => {
    if (!pathname) return;
    const { pageList } = await getDesignV2({ languageType: targetLanguageType });

    let targetPath = '';
    if (SOLUTION_TYPE === SOLUTION.JOBFLEX) {
      targetPath = pathname.slice('/career/'.length);
    } else {
      targetPath = pathname.slice('/'.length);
    }
    const targetPage = pageList.find((page) => page.urlPath === targetPath);
    const mainPage = pageList.find((page) => page.mainPageYn)!;

    if (hasSearchParam('viewType')) {
      const newUrl = buildUrl(`${SOLUTION_TYPE === SOLUTION.JOBFLEX ? '/career' : ''}/${mainPage.urlPath}`)
        .withSearchParam('viewType', getSearchParam('viewType'))
        .withSearchParam('languageType', targetLanguageType !== LANGUAGE.KOR ? targetLanguageType : null)
        .getResult();
      router.push(newUrl);
      return;
    }
    if (targetPage) {
      const newUrl = buildUrl(`/${targetPage.urlPath}`)
        .withSearchParam('languageType', targetLanguageType !== LANGUAGE.KOR ? targetLanguageType : null)
        .getResult();
      handleUrlClick({
        url: newUrl,
        isExternal: false,
      });
    } else {
      const newUrl = buildUrl(`/${mainPage.urlPath}`)
        .withSearchParam('languageType', targetLanguageType !== LANGUAGE.KOR ? targetLanguageType : null)
        .getResult();
      handleUrlClick({
        url: newUrl,
        isExternal: false,
      });
    }
  };

  if (!isSuccess) return null;

  return (
    <>
      {showPopupList &&
        createPortal(
          <PopupList isMobile={isMobile} popupList={popupList as PopupListPropType} options={{ pageLinkList }} />,
          document.getElementById('popup-root')!
        )}

      <div ref={ref} className={cx('contentsArea')}>
        <div className={cx('contentsWrap')}>
          <ViewHeader
            viewMode={viewMode}
            viewHeader={viewHeader}
            options={{
              pageMetadataHooks,
              languageType,
              hasLanguageChangeSwitch: useStatus === 'ON',
              handleLanguageSwitch,
            }}
            title={title}
          />
          {/*FIXME : layout 단 안먹는현상 수정 해야함*/}
          {viewType === 'QnA' ? (
            <QnAWrapper viewMode={viewMode} />
          ) : viewType === 'ResumeUpdate' ? (
            <ResumeUpdatePage viewMode={viewMode} />
          ) : (
            <ViewPage
              viewPageProps={viewPageProps}
              viewMode={viewMode}
              viewPage={viewPage}
              options={{ channelTalkActiveStatus, pageMetadataHooks, pageLinkList, languageType }}
            />
          )}
          <ViewFooter
            viewMode={viewMode}
            viewFooter={viewFooter}
            policyList={privatePolicyList?.privacyPolicyList ?? []}
            options={{ languageType: languageType }}
            //@ts-ignore
            querySelectedPolicy={useQueryGetCompanyPrivacyPolicy}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
