import IViewLayout from '../../../../interface/layout/IViewLayout';
import { FC } from 'react';
import { HasOption } from '../../../../interface/common/hasOption';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import { SLIDER_VIEW } from '../../../../interface/layout/slider/sliderStyle/ISliderStyle';
import ViewSliderMobileContainerType from './ViewSliderMobileContainerType/ViewSliderMobileContainerType';
import ViewSliderMobileOverflowType from './ViewSliderMobileOverflowType/ViewSliderMobileOverflowType';
import ViewSliderMobileFullScreenType from './ViewSliderMobileFullScreenType/ViewSliderMobileFullScreenType';

interface IProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewSliderMobile: FC<IProps & HasOption> = ({ viewMode, viewLayout, isCapture, isPreview, isEdit, options }) => {
  const slider = viewLayout.slider!;
  const viewType = slider.style.viewType;

  switch (viewType) {
    case SLIDER_VIEW.CONTAINER:
      return (
        <ViewSliderMobileContainerType
          viewMode={viewMode}
          slider={slider}
          isCapture={isCapture}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      );
    case SLIDER_VIEW.FULL_SCREEN:
      return (
        <ViewSliderMobileFullScreenType
          viewMode={viewMode}
          slider={slider}
          isCapture={isCapture}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      );
    case SLIDER_VIEW.OVERFLOW:
      return (
        <ViewSliderMobileOverflowType
          viewMode={viewMode}
          slider={slider}
          isCapture={isCapture}
          isPreview={isPreview}
          isEdit={isEdit}
          options={options}
        />
      );
  }
};

export default ViewSliderMobile;
