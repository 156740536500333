import { OnOffType } from '../../../../constants/common';
import { BorderRadiusStyle } from '../../../common/Border';
import { INavigationStyle } from './INavigationStyle';
import { ISliderItemImageSize } from './ISliderItemImageSize';
import { IAutoSlide } from './IAutoSlide';

export const SLIDER_VIEW = {
  CONTAINER: 'CONTAINER', // 컨테이너 슬라이더
  FULL_SCREEN: 'FULL_SCREEN', // 풀스크린 슬라이더
  OVERFLOW: 'OVERFLOW', // 오버플로 슬라이더
} as const;

export type SliderViewType = (typeof SLIDER_VIEW)[keyof typeof SLIDER_VIEW];

export const SLIDER_TEXT_LOCATION_TYPE = {
  OVERLAY: 'OVERLAY',
  BELOW: 'BELOW',
} as const;

export type SliderTextLocationType = (typeof SLIDER_TEXT_LOCATION_TYPE)[keyof typeof SLIDER_TEXT_LOCATION_TYPE];

export const DEFAULT_TOP_BOTTOM_PADDING = 20;

export interface ISliderStyle {
  viewType: SliderViewType; // 슬라이더 스타일
  navigationStyle: INavigationStyle; // 네비게이션 스타일
  sliderItemImageSize: ISliderItemImageSize; // 슬라이더 이미지 사이즈
  borderRadius: BorderRadiusStyle;
  autoSlide: IAutoSlide; // 슬라이더 자동 전환
  textLocationType: SliderTextLocationType; // 슬라이더 구성 > 텍스트 위치
  overlayColor: string; // 슬라이더 구성 > 오버레이 색상
  overlayRatio: number; // 슬라이더 구성 > 오버레이 투명도
  imageShowStatus: OnOffType;
  titleShowStatus: OnOffType;
  descriptionShowStatus: OnOffType;
  topBottomPadding: number;
}
