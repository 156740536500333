import useFontContext from '@hook/brand/font/useFontContext';
import useBoardContext from '@hook/useBoardContext';
import useRecruitmentContext from '@hook/useRecruitmentContext';
import { useMemo } from 'react';
import { IViewPageProps } from 'ui/component/page/useViewPage';

const useViewPage = () => {
  const { viewRecruitmentProps } = useRecruitmentContext();
  const { viewBoardProps } = useBoardContext();
  const { viewFontProps } = useFontContext();

  const viewPageProps: IViewPageProps = useMemo(
    () => ({
      viewRecruitmentProps,
      viewBoardProps,
      viewFontProps,
    }),
    [viewRecruitmentProps, viewBoardProps, viewFontProps]
  );

  return {
    viewPageProps,
  };
};

export default useViewPage;
