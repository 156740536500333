import styles from './TooltipText.module.scss'
import classNames from 'classnames/bind'
import { ReactNode } from 'react'

const cx = classNames.bind(styles)

interface TooltipTextProps {
    children?: ReactNode
}

const _TooltipText = ({ children }: TooltipTextProps) => {
    return <div className={cx('tooltipText')}>{children}</div>
}

export default _TooltipText
