import React, { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewGroup.module.scss';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import IViewGroup from '../../interface/group/IViewGroup';
import ViewContents from '../contents/ViewContents';
import IPageStyle from '../../interface/page/IPageStyle';
import { Block, BlockType } from '../../interface/block/IBlock';
import { HasOption, PageList } from '../../interface/common/hasOption';
import { DEFAULT_PAGE_STYLE, defaultGroupImageStyle } from 'ats-editor/src/constant/DefaultEditor';
import { isOnOff, OFF, ON_OFF } from '../../constants/common';
import { GroupPaddingMap } from '../../interface/common/Padding';
import { DefaultBorder } from '../../interface/common/Border';
import ViewGroupBackground from './ViewGroupBackground/ViewGroupBackground';
import useClickLinkedElement from '../../hook/link/useClickLinkedElement';
const cx = classnames.bind(styles);

interface IViewGroupProps {
  viewMode?: ViewModeType;
  pageStyle: IPageStyle;
  viewGroup: IViewGroup;
  blockType: BlockType;
  contentsGroupGap: number;
  isPreview?: boolean;
  previewTabType?: ViewModeType;
  isEdit?: boolean;
}

const ViewGroup: FC<IViewGroupProps & HasOption> = ({
  viewMode,
  pageStyle,
  viewGroup,
  blockType,
  contentsGroupGap,
  isPreview,
  isEdit,
  previewTabType,
  options,
}) => {
  const { contents } = viewGroup;
  const pageLinkList = options?.pageLinkList as PageList;
  const languageType = options && options['languageType'];
  const hyperLink = viewGroup.hyperLink;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const hasLink = isOnOff(hyperLink?.linkStatusType ?? OFF);
  const handleClick = () => {
    if (!hasLink) return;
    handleClickLinkedElement(hyperLink, isPreview, isEdit, languageType);
  };

  const gap = isOnOff(pageStyle.isToggledGap)
    ? `${(pageStyle ?? DEFAULT_PAGE_STYLE).contentsGap}px`
    : `${DEFAULT_PAGE_STYLE.contentsGap}px`;

  const typedStyle = useMemo(() => {
    const desktopImageStyle = viewGroup.style?.desktopImageStyle ?? defaultGroupImageStyle;
    const mobileImageStyle = viewGroup.style?.mobileImageStyle ?? defaultGroupImageStyle;
    if (viewMode === VIEW_MODE.MOBILE && isOnOff(viewGroup.style?.mobileImageStyleStatus ?? ON_OFF.OFF))
      return mobileImageStyle;
    return desktopImageStyle;
  }, [viewMode, viewGroup.style]);

  const getWidth = () => {
    if (viewMode === VIEW_MODE.TABLET && blockType === Block.DEPTH4) return `calc(50% - ${contentsGroupGap / 2}px)`;
    return '100%';
  };
  const getPadding = () => {
    const { paddingTop, paddingRight, paddingBottom, paddingLeft } = typedStyle.padding ?? GroupPaddingMap.EMPTY;
    return `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
  };
  const getBorderRadius = () => {
    const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = typedStyle.borderRadius ?? DefaultBorder;
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  };
  const isBorderRadius = () => {
    if (!typedStyle.borderRadius) return false;
    const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = typedStyle.borderRadius;
    return leftTopRadius || rightTopRadius || rightDownRadius || leftDownRadius;
  };

  const contentsEls = contents.map((viewContents) => {
    return (
      <ViewContents
        key={viewContents.id}
        viewMode={viewMode}
        viewContents={viewContents}
        isPreview={isPreview}
        isEdit={isEdit}
        previewTabType={previewTabType}
        options={options}
      />
    );
  });

  return (
    <div
      className={cx('container', {
        shadow: isOnOff(typedStyle.shadowStatus ?? ON_OFF.OFF),
        'overflow-hidden': isBorderRadius(),
        'has-link': hasLink,
      })}
      style={{
        width: getWidth(),
        padding: getPadding(),
        borderRadius: getBorderRadius(),
      }}
      onClick={handleClick}
    >
      <ViewGroupBackground typedStyle={typedStyle} viewGroup={viewGroup} viewMode={viewMode} />
      <div
        className={cx('content')}
        style={{
          gap,
        }}
      >
        {contentsEls}
      </div>
    </div>
  );
};

export default ViewGroup;
