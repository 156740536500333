export const getMarkerSVG = (markerColor: string) => {
  const markerSVG = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="${markerColor}" xmlns="http://www.w3.org/2000/svg">
      <g id="icon_pin_32_filled">
      <path id="Vector" d="M12.0004 4.14998C11.1257 4.14586 10.2591 4.31644 9.45122 4.65169C8.6434 4.98693 7.91057 5.48006 7.29585 6.10225C6.68111 6.72443 6.19678 7.46315 5.87128 8.27498C5.5458 9.08678 5.38569 9.95542 5.40034 10.83C5.40034 14.0449 8.11037 16.8899 11.0354 19.4849C11.3024 19.7192 11.6452 19.8489 12.0004 19.85C12.3542 19.8495 12.6956 19.7197 12.9603 19.4849C15.8903 16.8899 18.6003 14.0449 18.6003 10.83C18.615 9.95542 18.4549 9.08678 18.1294 8.27498C17.8039 7.46315 17.3196 6.72443 16.7049 6.10225C16.0901 5.48006 15.3574 4.98693 14.5495 4.65169C13.7417 4.31644 12.875 4.14586 12.0004 4.14998ZM14.4353 11.2699C14.3346 11.7045 14.1194 12.1042 13.8122 12.4275C13.505 12.7508 13.1168 12.9861 12.6879 13.1088C12.2591 13.2316 11.8052 13.2374 11.3734 13.1256C10.9416 13.0138 10.5476 12.7885 10.2322 12.4732C9.91675 12.1577 9.69152 11.7637 9.5797 11.3318C9.46795 10.9001 9.47372 10.4462 9.5965 10.0174C9.71927 9.58853 9.95447 9.2004 10.2779 8.89313C10.6012 8.58585 11.0009 8.37068 11.4353 8.26995C11.8484 8.17448 12.2788 8.1855 12.6863 8.30205C13.0939 8.41868 13.4651 8.63693 13.765 8.9364C14.065 9.23588 14.2838 9.60675 14.4011 10.0141C14.5184 10.4214 14.5302 10.8518 14.4353 11.265V11.2699Z" fill="${markerColor}"/>
      </g>
    </svg>
  `;

  const encoded = encodeURIComponent(markerSVG);

  return `data:image/svg+xml;charset=utf-8,${encoded}`;
};
