import { EDITOR_BLOCK_WIDTH } from '../../../constants/editor/block.constant';
import { SIZE, SizeType } from '../../common/Size';
import { IBannerSize } from './IBannerDesktopStyle';

export const BANNER_TYPE = {
  BASIC: 'BASIC',
  SLIDE: 'SLIDE',
} as const;
export type BannerType = (typeof BANNER_TYPE)[keyof typeof BANNER_TYPE];

export const BANNER_OPTION = {
  IMAGE: 'IMAGE',
  COLOR: 'COLOR',
} as const;
export type BannerOptionType = (typeof BANNER_OPTION)[keyof typeof BANNER_OPTION];

export const BannerSizeMap: Record<SizeType, IBannerSize> = {
  SMALL: {
    sizeType: SIZE.SMALL,
    width: EDITOR_BLOCK_WIDTH,
    height: 360,
  },
  MEDIUM: {
    sizeType: SIZE.MEDIUM,
    width: EDITOR_BLOCK_WIDTH,
    height: 720,
  },
  LARGE: {
    sizeType: SIZE.LARGE,
    width: EDITOR_BLOCK_WIDTH,
    height: 960,
  },
  CUSTOM: {
    sizeType: SIZE.CUSTOM,
    width: EDITOR_BLOCK_WIDTH,
    height: 720,
  },
};

export const BANNER_TEXT_LOCATION = {
  TOP: 'TOP',
  CENTER: 'CENTER',
  BOTTOM: 'BOTTOM',
} as const;
export type BannerTextLocationType = (typeof BANNER_TEXT_LOCATION)[keyof typeof BANNER_TEXT_LOCATION];

export const BANNER_MOBILE_ALIGN = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
} as const;
export type BannerMobileAlignType = (typeof BANNER_MOBILE_ALIGN)[keyof typeof BANNER_MOBILE_ALIGN];
export const BANNER_MOBILE_ALIGN_STYLE_MAP = {
  [BANNER_MOBILE_ALIGN.LEFT]: 'start',
  [BANNER_MOBILE_ALIGN.CENTER]: 'center',
  [BANNER_MOBILE_ALIGN.RIGHT]: 'end',
} as const;
