import { IButton } from './button/IButton';
import { IEmpty } from './empty/IEmpty';
import { IImage } from './image/IImage';
import { IText } from './text/IText';
import { GroupIdType } from '../group/IGroup';
import { IRecruitment } from './recruitment/IRecruitment';
import { IAccordion } from './accordion/IAccordion';
import { IBoard } from './board/IBoard';
import IMargin from './margin/IMargin';
import { IDivider } from './divider/IDivider';
import { IVideo } from './video/IVideo';
import { LayoutContentType } from '../layout/ILayout';
import { IMap } from './map/IMap';

export const CONTENTS = {
  BUTTON: 'BUTTON',
  EMPTY: 'EMPTY',
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  RECRUITMENT: 'RECRUITMENT',
  ACCORDION: 'ACCORDION',
  BOARD: 'BOARD',
  MARGIN: 'MARGIN',
  LINE: 'LINE',
  VIDEO: 'VIDEO',
  MAP: 'MAP',
} as const;

export type ContentsType = keyof typeof CONTENTS;

export const contentsTextMap: Record<ContentsType, string> = {
  [CONTENTS.BUTTON]: '버튼',
  [CONTENTS.EMPTY]: '',
  [CONTENTS.IMAGE]: '이미지',
  [CONTENTS.TEXT]: '텍스트',
  [CONTENTS.RECRUITMENT]: '채용공고',
  [CONTENTS.ACCORDION]: '아코디언',
  [CONTENTS.BOARD]: '게시판',
  [CONTENTS.MARGIN]: '여백',
  [CONTENTS.LINE]: '구분선',
  [CONTENTS.VIDEO]: '동영상',
  [CONTENTS.MAP]: '지도',
};

export type IContentsType =
  | IButton
  | IEmpty
  | IImage
  | IText
  | IRecruitment
  | IAccordion
  | IBoard
  | IMargin
  | IDivider
  | IVideo
  | IMap;

export type ContentsIdType = `contents_${string}`;

export type IContents<T> = {
  id: ContentsIdType;
  type: ContentsType;
  parentId: GroupIdType;
} & T;

export interface IRNBContents {
  key: ContentsType | LayoutContentType;
  icon: string;
  title: string;
  description: string;
  altTitle?: string;
}
