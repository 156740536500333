import { ContentsIdType } from '../contents/IContents';
import { BlockIdType } from '../block/IBlock';
import { IGroupStyle } from './IGroupStyle';
import { Nullable } from '../common/common';
import type { LinkStyle } from '../common/Link';

export const GROUP = {
  DEFAULT: 'GROUP',
} as const;

export const GROUP_OPTION = {
  IMAGE: 'IMAGE',
  COLOR: 'COLOR',
} as const;

export type GroupOptionType = keyof typeof GROUP_OPTION;

export type GroupIdType = `group_${string}`;

type IGroup = {
  id: GroupIdType;
  parentId: BlockIdType;
  columnNumber: number;
  pcSortOrder: number;
  mobileSortOrder: number;

  style: Nullable<IGroupStyle>;

  hyperLink: Nullable<LinkStyle>;
  contentsIds: ContentsIdType[];
};

export default IGroup;
