import { FC, PropsWithChildren } from 'react';
import { VERTICAL_ALIGN } from 'ui/interface/layout/slider/sliderStyle/INavigationStyle';
import ViewSliderNavigator from './ViewSliderNavigator';
import { ISliderStyle } from '../../../../interface/layout/slider/sliderStyle/ISliderStyle';
import { isOnOff } from '../../../../constants/common';

interface IProps {
  sliderStyle: ISliderStyle;
  canMovePrev: boolean;
  onClickPrev: () => void;
  canMoveNext: boolean;
  onClickNext: () => void;
}

const ViewSliderNavigatorProvider: FC<PropsWithChildren<IProps>> = ({ children, ...navigatorProps }) => {
  const navigationStyle = navigatorProps.sliderStyle.navigationStyle;

  const isTop = navigationStyle.verticalAlignType === VERTICAL_ALIGN.TOP;
  const isBottom = navigationStyle.verticalAlignType === VERTICAL_ALIGN.BOTTOM;
  const needToRender = isOnOff(navigationStyle.activeStatus);

  if (!needToRender) {
    return <>{children}</>;
  }

  return (
    <>
      {isTop && <ViewSliderNavigator {...navigatorProps}></ViewSliderNavigator>}
      {children}
      {isBottom && <ViewSliderNavigator {...navigatorProps}></ViewSliderNavigator>}
    </>
  );
};

export default ViewSliderNavigatorProvider;
