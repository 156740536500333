import classnames from 'classnames/bind';
import styles from '../ViewBanner.module.scss';
import { isOnOff, OFF } from '../../../../constants/common';
import { FC, useMemo } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../../interface/common/ViewMode';
import IViewLayout from '../../../../interface/layout/IViewLayout';
import { HasOption, PageList } from '../../../../interface/common/hasOption';
import useClickLinkedElement from '../../../../hook/link/useClickLinkedElement';
import ViewBannerColor from '../ViewBannerColor';
import ViewBannerImage from '../ViewBannerImage';
import useIntersectionObserver from '../../../../hook/useIntersectionObserver';
import useScrollToBottom from '../../../../hook/useScrollToBottom';
import {
  BANNER_MOBILE_ALIGN,
  BANNER_MOBILE_ALIGN_STYLE_MAP,
  BANNER_OPTION,
} from '../../../../interface/layout/banner/banner.constant';
const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}
const ViewBannerBasic: FC<IProps & HasOption> = ({ viewMode, viewLayout, isCapture, isPreview, isEdit, options }) => {
  const pageLinkList = options?.pageLinkList as PageList;
  const languageType = options && options['languageType'];
  const type = viewLayout.banner?.bannerBasic?.type ?? BANNER_OPTION.IMAGE;
  const hyperLink = viewLayout.banner?.bannerBasic.hyperLink;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);

  const hasLink = isOnOff(hyperLink?.linkStatusType ?? OFF);
  const handleClick = () => {
    handleClickLinkedElement(hyperLink, isPreview, isEdit, languageType);
  };

  // 스크롤 hook
  const { isIntersection, setIsIntersection } = useIntersectionObserver({
    idList: [viewLayout.id],
  });

  // 바닥 감지 hook
  useScrollToBottom({
    onBottom: () => setIsIntersection(true),
  });

  const getImgEl = () => {
    switch (type) {
      case BANNER_OPTION.COLOR:
        return (
          <ViewBannerColor
            viewMode={viewMode}
            viewLayout={viewLayout}
            isPreview={isPreview}
            options={options}
            onClick={handleClick}
          />
        );
      case BANNER_OPTION.IMAGE:
        return (
          <ViewBannerImage
            viewMode={viewMode}
            viewLayout={viewLayout}
            options={options}
            isPreview={isPreview}
            isCapture={isCapture}
            onClick={hasLink ? handleClick : undefined}
          />
        );
    }
  };

  const mobileImageAlign = useMemo(() => {
    const DEFAULT_ALIGN = 'center';

    if (type === BANNER_OPTION.COLOR) return DEFAULT_ALIGN;
    if (viewMode !== VIEW_MODE.MOBILE) return DEFAULT_ALIGN;
    if (!isOnOff(viewLayout.banner?.bannerBasic?.mobileStyleToggle ?? OFF)) return DEFAULT_ALIGN;

    return BANNER_MOBILE_ALIGN_STYLE_MAP[
      viewLayout.banner?.bannerBasic.mobileStyle?.alignType ?? BANNER_MOBILE_ALIGN.CENTER
    ];
  }, [viewLayout, viewMode, type]);

  return (
    <div
      className={cx('bannerWrap', { fadeIn: !isCapture && isIntersection }, { isCapture })}
      style={{ justifyContent: mobileImageAlign }}
    >
      {getImgEl()}
    </div>
  );
};

export default ViewBannerBasic;
