import { BlockIdType } from '../block/IBlock';
import { PageIdType } from '../page/IPage';
import { ILayoutStyle } from './ILayoutStyle';
import IBanner from './banner/IBanner';
import ISlider from './slider/ISlider';

// 레이아웃 레벨에서의 컨텐츠
export const LAYOUT_CONTENT = {
  BANNER: 'BANNER',
  SLIDER: 'SLIDER',
} as const;

export const LAYOUT = {
  DEFAULT: 'LAYOUT',
  CONTENTS: 'CONTENTS',
  EMPTY: 'EMPTY',
  ...LAYOUT_CONTENT,
} as const;

export const LAYOUT_OPTION = {
  IMAGE: 'IMAGE',
  COLOR: 'COLOR',
} as const;

export type LayoutOptionType = keyof typeof LAYOUT_OPTION;
export type LayoutContentType = keyof typeof LAYOUT_CONTENT;
export type LayoutType = keyof typeof LAYOUT;

export type LayoutIdType = `layout_${string}`;

export type ILayout = {
  id: LayoutIdType;
  type: LayoutType;
  parentId: PageIdType;
  style: ILayoutStyle;
  blockIds: BlockIdType[];
  banner?: IBanner;
  slider?: ISlider;
};
