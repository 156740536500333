import classNames from 'classnames/bind';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import IconButton from 'ui/common/button/IconButton';
import Portal from 'ui/common/portal/Portal';
import TextFieldBase from '../../../../common/textfield/TextFieldBase';
import { COLORS } from '../../../../constants/colors';
import { SolutionType } from '../../../../constants/solution/solution.constant';
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import styles from './KeywordFilter.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  solution?: SolutionType;
  viewMode?: ViewModeType;
  keyword?: string;
  keywordInput: string;
  handleInputKeyword?: (value: string) => void;
  handleInputKeywordInput: (value: string) => void;
  handleReset?: () => void;
  disabled?: boolean;
  className?: string;
}

const KeywordFilter: FC<IProps> = ({
  // solution = SOLUTION.JOBDA, // 사용 안 하지만 추후 사용 고려하여 남겨둠
  viewMode,
  keyword,
  keywordInput,
  handleInputKeyword = () => {},
  handleInputKeywordInput,
  disabled = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileInputRef = useRef<HTMLInputElement>(null);

  const { multiLanguageText } = useMultiLanguage();
  const searchPlaceholder = multiLanguageText('공고 검색하기', 'Search');

  useEffect(() => {
    if (isOpen) {
      mobileInputRef.current?.focus();
    }
  }, [isOpen]);

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleInputKeyword(keywordInput);
      close();
    }
  };

  const handleViewerInputKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    const pattern = /([ㄱ-ㅣ]$)/i;
    const text = e.target.value;
    if (!pattern.test(text)) {
      handleInputKeyword(text);
    }
    handleInputKeywordInput(text);
  };

  const searchIconProps = useMemo(() => {
    return { color: disabled ? COLORS.gray400 : COLORS.gray600 };
  }, [disabled]);

  return (
    <>
      <div className={cx('wrapper', isOpen && 'open', viewMode, className)}>
        {viewMode === 'MOBILE' ? (
          <>
            <TextFieldBase
              placeholder={searchPlaceholder}
              className={cx('search-input')}
              value={keyword}
              onClick={open}
              disabled={disabled}
              size={'lg'}
              leftIcon={searchIconProps}
            />
            <Portal domId={'tooltip-root'}>
              <div
                className={cx('tooltip-wrapper', {
                  open: isOpen,
                })}
              >
                <div className={cx('header')}>
                  <h4 className={cx('title')}>필터</h4>
                  <IconButton icon={'close_line'} onClick={close} />
                </div>
                <div className={cx('options')}>
                  <TextFieldBase
                    placeholder={searchPlaceholder}
                    className={cx('search-input')}
                    value={keywordInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputKeywordInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    disabled={disabled}
                    size={'lg'}
                    ref={mobileInputRef}
                    leftIcon={searchIconProps}
                  />
                </div>
              </div>
            </Portal>
          </>
        ) : (
          <>
            <TextFieldBase
              placeholder={searchPlaceholder}
              className={cx('search-input')}
              value={keywordInput}
              onChange={handleViewerInputKeyword}
              disabled={disabled}
              size={'lg'}
              leftIcon={searchIconProps}
            />
          </>
        )}
      </div>
    </>
  );
};

export default KeywordFilter;
