import styles from './TooltipTitle.module.scss'
import classNames from 'classnames/bind'
import { ReactNode } from 'react'

const cx = classNames.bind(styles)

interface TooltipTitleProps {
    children?: ReactNode
}

const _TooltipTitle = ({ children }: TooltipTitleProps) => {
    return <div className={cx('tooltipTitle')}>{children}</div>
}

export default _TooltipTitle
