'use client';

import { createContext, FC, ReactNode, useState } from 'react';
import { TCareerType, TOpenStatus, TSubmissionStatusType } from 'ui/interface/recruit/IRecruitSetting';

interface IRecruitSettingContextValueProps {
  children: ReactNode;
}

interface IRecruitSettingContextValue {
  keyword: string;
  tag: string[];
  jobGroup: number[];
  careerType: TCareerType[];
  workingArea: number[];
  openStatus: TOpenStatus[];
  submissionStatus: TSubmissionStatusType[];
  setKeyword: (value: string) => void;
  setTag: (value: string[]) => void;
  setCareerType: (value: TCareerType[]) => void;
  setJobGroup: (value: number[]) => void;
  setWorkingArea: (value: number[]) => void;
  setSubmissionStatus: (value: TSubmissionStatusType[]) => void;
  setOpenStatus: (value: TOpenStatus[]) => void;
}

const initialRecruitSettingContextValue: IRecruitSettingContextValue = {
  keyword: '',
  tag: [],
  jobGroup: [],
  careerType: [],
  workingArea: [],
  submissionStatus: [],
  openStatus: [],
  setKeyword: () => {},
  setTag: () => {},
  setCareerType: () => {},
  setJobGroup: () => {},
  setWorkingArea: () => {},
  setSubmissionStatus: () => {},
  setOpenStatus: () => {},
};

const RecruitSettingContext = createContext(initialRecruitSettingContextValue);

export const RecruitSettingContextProvider: FC<IRecruitSettingContextValueProps> = (props) => {
  // 지원자사이트 채용공고 설정에서 설정된 옵션값들
  const [keyword, setKeyword] = useState<string>('');
  const [tag, setTag] = useState<string[]>([]);
  const [jobGroup, setJobGroup] = useState<number[]>([]);
  const [careerType, setCareerType] = useState<TCareerType[]>([]);
  const [workingArea, setWorkingArea] = useState<number[]>([]);
  const [submissionStatus, setSubmissionStatus] = useState<TSubmissionStatusType[]>([]);

  const [openStatus, setOpenStatus] = useState<TOpenStatus[]>([]);
  const { children } = props;

  return (
    <RecruitSettingContext.Provider
      value={{
        tag,
        keyword,
        careerType,
        workingArea,
        jobGroup,
        submissionStatus,
        openStatus,
        setTag,
        setKeyword,
        setCareerType,
        setJobGroup,
        setWorkingArea,
        setSubmissionStatus,
        setOpenStatus,
      }}
    >
      {children}
    </RecruitSettingContext.Provider>
  );
};

export default RecruitSettingContext;
