import classNames from 'classnames/bind';
import { FC, useContext } from 'react';
import SolutionContext from '../../../context/SolutionContext';
import RecruitListInjectedContext from '../../../context/recruiter/RecruitListInjectedContext';
import RecruitSettingInjectedContext from '../../../context/recruiter/RecruitSettingInjectedContext';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../interface/contents/recruitment/IRecruitment';
import { RECRUITMENT_VIEW } from '../../../interface/contents/recruitment/IRecruitmentStyle';
import { TSortType } from '../../../interface/recruit/IRecruitSetting';
import RecruitHeaderSetting from '../../recruiter/recruitHeaderSetting/RecruitHeaderSetting';
import style from './ViewRecruitment.module.scss';
import RecruitViewList from './list/RecruitViewList';
import RecruitViewSetting from './setting/RecruitViewSetting';
import RecruitViewSideSetting from './sideSetting/RecruitViewSideSetting';

const cx = classNames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewRecruitment: FC<IProps> = ({ viewMode, viewRecruitment, isPreview, isEdit }) => {
  const showSideFilter = viewRecruitment.style.viewType === RECRUITMENT_VIEW.SIDE_FILTER;
  const { solution } = useContext(SolutionContext);
  const { mutatePostListData: _mutatePostListData, mutatePostListPreviewData: _mutatePostListPreviewData } =
    useContext(RecruitListInjectedContext);
  const { queryGetSettingData, setQueryGetSettingData } = useContext(RecruitSettingInjectedContext);

  const mutatePostListData = isPreview || isEdit ? _mutatePostListPreviewData : _mutatePostListData;

  return (
    <div className={cx('container')}>
      {showSideFilter && viewMode === VIEW_MODE.PC && (
        <div className={cx('column')}>
          <RecruitViewSideSetting viewMode={viewMode} tagFilterName={viewRecruitment.value.tagFilterName} />
        </div>
      )}
      <div className={cx('column', 'content')}>
        {showSideFilter && viewMode === VIEW_MODE.PC ? (
          <RecruitHeaderSetting
            solution={solution}
            totalCount={mutatePostListData.pagination.totalCount ?? 0}
            sortType={queryGetSettingData.sortType}
            changeSortType={(value: TSortType) => {
              setQueryGetSettingData({
                ...queryGetSettingData,
                sortType: value,
              });
            }}
          />
        ) : (
          <RecruitViewSetting viewMode={viewMode} viewRecruitment={viewRecruitment} isPreview={isPreview} />
        )}
        <RecruitViewList viewMode={viewMode} viewRecruitment={viewRecruitment} isPreview={isPreview} isEdit={isEdit} />
      </div>
    </div>
  );
};

export default ViewRecruitment;
