export enum EnumPadding {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  CUSTOM = 'CUSTOM',
  EMPTY = 'EMPTY',
}

export type PaddingType = keyof typeof EnumPadding;

export type PaddingStyle = {
  type: PaddingType;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
};

export const BlockPaddingMap: Record<PaddingType, PaddingStyle> = {
  SMALL: {
    type: EnumPadding.SMALL,
    paddingTop: 16,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 0,
  },
  MEDIUM: {
    type: EnumPadding.MEDIUM,
    paddingTop: 24,
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 0,
  },
  LARGE: {
    type: EnumPadding.LARGE,
    paddingTop: 32,
    paddingRight: 0,
    paddingBottom: 32,
    paddingLeft: 0,
  },
  CUSTOM: {
    type: EnumPadding.CUSTOM,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  EMPTY: {
    type: EnumPadding.EMPTY,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};

export const GroupPaddingMap: Record<PaddingType, PaddingStyle> = {
  SMALL: {
    type: EnumPadding.SMALL,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
  },
  MEDIUM: {
    type: EnumPadding.MEDIUM,
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    paddingLeft: 24,
  },
  LARGE: {
    type: EnumPadding.LARGE,
    paddingTop: 24,
    paddingRight: 32,
    paddingBottom: 24,
    paddingLeft: 32,
  },
  CUSTOM: {
    type: EnumPadding.CUSTOM,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  EMPTY: {
    type: EnumPadding.EMPTY,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};

export const TextPaddingMap: Record<PaddingType, PaddingStyle> = {
  SMALL: {
    type: EnumPadding.SMALL,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
  },
  MEDIUM: {
    type: EnumPadding.MEDIUM,
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    paddingLeft: 24,
  },
  LARGE: {
    type: EnumPadding.LARGE,
    paddingTop: 24,
    paddingRight: 32,
    paddingBottom: 24,
    paddingLeft: 32,
  },
  CUSTOM: {
    type: EnumPadding.CUSTOM,
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    paddingLeft: 24,
  },
  EMPTY: {
    type: EnumPadding.EMPTY,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};
