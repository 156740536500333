import { LANGUAGE } from '../../../constants/language';
import { SOLUTION } from '../../../constants/solution/solution.constant';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { useUrl } from '../../../hook/useUrl';
import { SOLUTION_TYPE } from '../../../util/solution';

interface IProps {
  readOnly: boolean;
}

const useRecruitItemUrl = ({ readOnly }: IProps) => {
  const { languageType } = useMultiLanguage();
  const { buildUrl } = useUrl();

  const getRecruitUrl = (positionSn: number, JDUrl: string) => {
    return readOnly
      ? buildUrl(`/recruit/${positionSn}`)
          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
          .getResult()
      : buildUrl(`/${SOLUTION_TYPE === SOLUTION.JOBFLEX ? `career/${JDUrl}` : JDUrl}/${positionSn}`)
          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
          .getResult();
  };

  return { getRecruitUrl };
};

export default useRecruitItemUrl;
