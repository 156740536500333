import classNames from 'classnames/bind';
import { useContext, useEffect } from 'react';
import DropdownWithOnClick from '../../../common/dropdownWithOnClick/Dropdown';
import { SORT_DEFINITION } from '../../../constants/position/common';
import { SOLUTION, SolutionType } from '../../../constants/solution/solution.constant';
import RecruitSettingInjectedContext from '../../../context/recruiter/RecruitSettingInjectedContext';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { TSortType } from '../../../interface/recruit/IRecruitSetting';
import style from './RecruitHeaderSetting.module.scss';

const cx = classNames.bind(style);

interface IProps {
  solution: SolutionType;
  totalCount: number;
  sortType: TSortType;
  changeSortType: (value: TSortType) => void;
  readOnly?: boolean;
}

const allSortOptions: Record<SolutionType, TSortType[]> = {
  [SOLUTION.JOBFLEX]: ['CREATED_DATE_TIME', 'END_DATE_TIME', 'KOREAN', 'SUBMISSION_STATUS'],
  [SOLUTION.JOBDA]: ['CREATED_DATE_TIME', 'END_DATE_TIME', 'KOREAN', 'SUBMISSION_STATUS'],
};

const RecruitHeaderSetting = ({ solution, totalCount, sortType, changeSortType, readOnly }: IProps) => {
  const { getSetting } = useContext(RecruitSettingInjectedContext);

  useEffect(() => {
    getSetting?.();
  }, [getSetting]);

  useEffect(() => {
    if (sortType === 'JOBFLEX_SORT') {
      changeSortType('CREATED_DATE_TIME');
    }
  }, [sortType, changeSortType]);

  const { multiLanguageText, languageType } = useMultiLanguage();

  return (
    <div className={cx('recruit-header-setting')}>
      <p className={cx('total-count')}>
        {multiLanguageText('총', 'Total')} <strong className={cx('total-count-number')}>{totalCount}</strong>{' '}
        {multiLanguageText('건의 채용공고가 있습니다.', 'Jobs Listings')}
      </p>
      <DropdownWithOnClick
        size={'sm'}
        className={cx('sort-dropdown')}
        options={(allSortOptions[solution] ?? []).map((sortType) => ({
          name: <span className={cx('option-label')}>{SORT_DEFINITION[sortType][languageType]}</span>,
          value: sortType,
        }))}
        value={sortType}
        onChange={(value) => changeSortType(value)}
        disabled={readOnly}
      />
    </div>
  );
};

export default RecruitHeaderSetting;
