import { VIEW_MODE, ViewModeType } from 'ui/interface/common/ViewMode';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import { useMemo } from 'react';

export interface UseBackgroundFileArgs {
  desktopFileUid: string;
  desktopFileUrl: string;
  mobileFileUid: string;
  mobileFileUrl: string;
  viewMode?: ViewModeType;
}

export const backgroundImageAcceptList = ['.jpg', '.jpeg', '.png', '.gif', 'image/gif', '.svg', '.svgz', '.webp'];
export const backgroundVideoAcceptList = ['.mp4', '.mov'];
export const backgroundMediaAccept = [...backgroundImageAcceptList, ...backgroundVideoAcceptList].join(',');

export const BACKGROUND_MEDIA = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type BackgroundMediaType = keyof typeof BACKGROUND_MEDIA;

export const useBackgroundFile = ({
  desktopFileUid,
  desktopFileUrl,
  mobileFileUid,
  mobileFileUrl,
  viewMode = VIEW_MODE.PC,
}: UseBackgroundFileArgs) => {
  const desktopBackgroundImageUrl = getImageURL({
    fileUid: desktopFileUid,
    fileUrl: desktopFileUrl,
  });
  const mobileBackgroundImageUrl = getImageURL({
    fileUid: mobileFileUid,
    fileUrl: mobileFileUrl,
  });

  const fileUrl = useMemo(() => {
    if (viewMode === VIEW_MODE.PC || viewMode === VIEW_MODE.TABLET) return desktopBackgroundImageUrl;
    if (viewMode === VIEW_MODE.MOBILE) {
      if (!mobileBackgroundImageUrl) return desktopBackgroundImageUrl;
      return mobileBackgroundImageUrl;
    }
  }, [mobileBackgroundImageUrl, desktopBackgroundImageUrl, viewMode]);

  const fileType = useMemo(() => {
    if (backgroundImageAcceptList.some((accept) => fileUrl?.endsWith(accept))) return BACKGROUND_MEDIA.IMAGE;
    if (backgroundVideoAcceptList.some((accept) => fileUrl?.endsWith(accept))) return BACKGROUND_MEDIA.VIDEO;
    return null;
  }, [fileUrl]);

  return {
    desktopBackgroundImageUrl,
    mobileBackgroundImageUrl,
    fileUrl,
    fileType,
  };
};
