import { IBlockStyle } from './IBlockStyle';
import { GroupIdType } from '../group/IGroup';
import { LayoutIdType } from '../layout/ILayout';

export enum Block {
  DEPTH1 = 'DEPTH1',
  DEPTH2 = 'DEPTH2',
  DEPTH3 = 'DEPTH3',
  DEPTH4 = 'DEPTH4',
}

export type BlockType = keyof typeof Block;

export const getGetBlockTypeByNumber = (number: number) => {
  switch (number) {
    case 1:
      return Block.DEPTH1;
    case 2:
      return Block.DEPTH2;
    case 3:
      return Block.DEPTH3;
    case 4:
      return Block.DEPTH4;
    default:
      return Block.DEPTH1;
  }
};

export type BlockIdType = `block_${string}`;

type IBlock = {
  id: BlockIdType;
  type: BlockType;
  parentId: LayoutIdType;
  pcSortOrder: string;
  mobileSortOrder: string;
  style: IBlockStyle;
  groupIds: GroupIdType[];
};

export default IBlock;
