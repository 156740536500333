import { useRef, useState } from 'react';
import Icon from '../../../common/icon/Icon';
import { COLORS } from '../../../constants/colors';
import style from './RecruitSideSettingAccordion.module.scss';

import classNames from 'classnames/bind';
import Button from '../../../common/button/Button';
import TextFieldBase from '../../../common/textfield/TextFieldBase';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { IOption } from '../../../interface/common/common';
import Space from '../../common/Space';
import { IRecruitSideSettingTagFilterProps } from './RecruitSideSetting';
const cx = classNames.bind(style);

interface IProps<T = string | number> {
  open?: boolean;
  title: string;
  options: IOption<T>[];
  selectedOptionValues: T[];
  handleClickOption: (option: IOption<T>) => void;
  showTotalOption?: boolean;
  tagFilterNameProps?: IRecruitSideSettingTagFilterProps;
}

export const TOTAL_OPTION_VALUE = '';

const RecruitSideSettingAccordion = ({
  open = true,
  title,
  options: _options,
  selectedOptionValues,
  handleClickOption,
  showTotalOption = true,
  tagFilterNameProps,
}: IProps) => {
  const { multiLanguageText } = useMultiLanguage();

  const TOTAL_OPTION: IOption = {
    label: multiLanguageText('전체', 'ALL'),
    value: TOTAL_OPTION_VALUE,
  };
  const options = showTotalOption ? [TOTAL_OPTION, ..._options] : _options;
  const isSelectedOptionValuesEmpty = selectedOptionValues.length === 0;

  const [isOpen, setIsOpen] = useState(open);
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const { isEditable, isEditing, inputValue, handleChangeInput, handleClickEditIcon, changeToReadMode } =
    tagFilterNameProps || {};

  return (
    <div className={cx('side-setting-accordion')}>
      <div className={cx('accordion-title-section')} onClick={handleToggleOpen}>
        {!isEditable ? (
          <span className={cx('title')}>{inputValue || title}</span>
        ) : isEditing ? (
          <TextFieldBase
            ref={inputRef}
            className={cx('title-input')}
            value={inputValue}
            onClick={(e) => e.stopPropagation()}
            onChange={handleChangeInput}
            onBlur={changeToReadMode}
            style={{ fontSize: '16px' }}
          />
        ) : (
          <div className={cx('title-area')}>
            <span className={cx('title')}>{inputValue || title}</span>
            {!isEditing && (
              <Icon
                className={cx('edit-icon', { open: isOpen })}
                name={'edit_line'}
                size={18}
                color={COLORS.gray800}
                onClick={(e) => {
                  handleClickEditIcon?.(e);
                  setTimeout(() => {
                    inputRef.current?.focus();
                  }, 0);
                }}
              />
            )}
          </div>
        )}
        <Icon
          className={cx('right-icon', { open: isOpen })}
          name={'arrow_bottom_line'}
          size={32}
          color={COLORS.gray800}
        />
      </div>
      <div className={cx('accordion-option-section', { open: isOpen })}>
        {options.map((option) => {
          const isTotalOption = !option.value;
          return (
            <Button
              key={option.value}
              variant={'outlined'}
              className={cx('option-item', {
                selected: isTotalOption ? isSelectedOptionValuesEmpty : selectedOptionValues.includes(option.value),
              })}
              onClick={() => handleClickOption(option)}
            >
              <span className={cx('option-label')}>{option.label}</span>
            </Button>
          );
        })}
        <Space width={'100%'} height={32} />
      </div>
    </div>
  );
};

export default RecruitSideSettingAccordion;
